<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Экология Прибайкалья-погружение»
		</h2>
		<p class="EventsPage-date">
			с 13 ноября по 26 ноября 2024
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=203" target="_blank">
			Moodle
		</a>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Экология Прибайкалья-погружение» для учащихся 6-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			В настоящее время перед обществом остро стала проблема загрязнения окружающей среды. Экологическое образование и
			воспитание экологической культуры возможно только при осознанном и заинтересованном участии экологической
			деятельности всего общества, в первую очередь наиболее активной части населения — школьников.
		</p>

		<p class="EventsPage-text">
			В рамках профильной смены учащиеся изучат эффективные способы обращения с отходами производства и потребления,
			повышения качества питьевой воды для населения и обеспечения баланса выбытия и воспроизводства лесов. Свои знания
			и умения учащиеся реализуют в своих проектах, которые создадут на профильной смене по 5 направлениям: «Отходы»,
			«Земля», «Вода», «Лес» и «Человек».
		</p>
		<hr>

		<!-- <p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">

			</li>
			<li class="JuniorProfi-items">

			</li>
			<li class="JuniorProfi-items">

			</li>
		</ul> -->

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (описание проекта) до 25 октября 2024 года на платформе
				Образовательного центра «Персей», системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, необходимо записаться на программу «Экология
				Прибайкалья-погружение» на ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 13 ноября по 26 ноября 2024 года на бесплатной основе в Образовательном центре
				«Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 30 октября на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<!-- <p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number"></li>

		</ol>
		<br>
		<hr> -->

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 5 ноября сентября 2024 г. в 20.00, <a
				style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1728004564">ссылка на подключение</a>
		</p>
		<hr>

		<!-- <p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<!-- <p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>