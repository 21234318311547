<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Спортивный туризм»
		</h2>
		<p class="EventsPage-date">
			с 19 по 28 июля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25366-programma-sportivnyi-turizm" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит профильную смену «Спортивный туризм» для обучающихся 5-11-х классов
			общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Спортивный туризм является, с одной стороны, видом спорта для всех, массовым средством оздоровления,
			разностороннего развития, воспитания волевых качеств, приобретения навыков самообслуживания, получения
			профессионально–прикладной подготовки к профессиям: спасателя, служащего МЧС, промальпиниста, служащего войск
			специального назначения (ВДВ, горные войска), с другой – популярным всесезонным видом спорта, нацеленным на высшие
			спортивные достижения.
		</p>

		<p class="EventsPage-text">
			Спортивный туризм сочетает в себе физические и умственные нагрузки на фоне влияния меняющихся внешних факторов.
			Занятия спортивным туризмом вырабатывают самостоятельность, решительность, целеустремленность, настойчивость,
			умение владеть собой, принимать правильные решения в условиях чрезвычайной ситуации.
		</p>

		<p class="EventsPage-text">
			Программа направлена на повышение уровня индивидуальных и групповых технико-тактических действий, чтобы в
			дальнейшем стать конкурентно способным в рамках сборной команды, развитие физических кондиций, позволяющих освоить
			технико-тактические действия дистанций 2-4 класса в природной среде.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели профильной смены:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Куксенко Леонид Михайлович, тренер-преподаватель МБУДО «ДЮСШ №1» г. Усолье-Сибирское, старший тренер сборной
				команды Иркутской области по спортивному туризму; Достижения: тренер-преподаватель высшей категории
			</li>
			<li class="JuniorProfi-items">
				Федоров Дмитрий Александрович, педагог дополнительного образования, ПСЧ ЗПСО ФПС ГПС ГУ МЧС России по Иркутской
				области, КМС по спортивному туризму. Достижения: КМС по спортивному туризму
			</li>
			<li class="JuniorProfi-items">
				Хабеев Степан Аркадьевич, заместитель начальника отр. по ПСР, ОГБУ ПСС ИО
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Участникам, прошедших на профильную смену необходимо подать заявку на платформе Навигатор дополнительного
				образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Стягайло Екатерина Станиславна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: e.kapustina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>