<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Основы фотосъемки»
		</h2>
		<p class="EventsPage-date">
			с 10 октября по 21 декабря 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=202" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/26747-programma-osnovy-fotosemki" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Основы фотосъемки» для учащихся 6-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Фотография является не только искусством, но и мощным инструментом самовыражения и коммуникации в современном
			мире. В век цифровых технологий и социальных сетей, умение делать качественные фотографии востребовано не только
			среди профессионалов, но и среди молодежи, которая активно использует визуальные медиа для личных и
			образовательных целей.
		</p>

		<p class="EventsPage-text">
			Дистанционный курс погружает учащихся в теоретические и практические основы фотографии, развивая навыки, которые
			помогут им создавать уникальные, эстетически привлекательные кадры и эффективно продвигать свои работы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Говорова Анастасия Андреевна, преподаватель отдела развития образовательных программ по направлению «Наука»
				Государственного автономного нетипового учреждения дополнительного образования Иркутской области «Региональный
				центр выявления и поддержки одаренных детей «Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 10 октября 2024 года на платформе Навигатор дополнительного образования детей Иркутской
				области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 10 октября 2024 года на платформе Moodle, Образовательного центра
				«Персей»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>