<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Греко-римская борьба»
		</h2>
		<p class="EventsPage-date">
			с 1 по 14 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24953-programma-greko-rimskaya-borba"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит профильную смену «Греко-римская борьба» для обучающихся 5-11-х классов
			общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Греко-римская борьба – не только вид спортивного единоборства, но и система воспитания, способствующая развитию
			морально-волевых качеств человека, патриотизма и гражданственности.
		</p>

		<p class="EventsPage-text">
			Греко-римская борьба закаляет характер, развивает стойкость и выносливость, которые необходимы в профессиональной
			и общественной деятельности. Занятия борьбой способствуют гармоничному развитию ребенка и способствует развитию
			интеллекта. Борцы отличаются атлетическим телосложением, крепкой сердечно-сосудистой и иммунной системой.
		</p>

		<p class="EventsPage-text">
			Программа способствует формированию потребности в здоровом образе жизни, мотивации к достижению спортивных
			результатов и целевой установки на спортивное совершенствование.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Участникам, прошедших на профильную смену необходимо подать заявку на платформе Навигатор дополнительного
				образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели профильной смены:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Иванов Сергей Брониславович, директор, тренер-преподаватель АНО Спортивный клуб «Добрыня»; Достижения: Мастер
				спорта России по греко-римской борьбе.
			</li>
			<li class="JuniorProfi-items">
				Бриль Иван Александрович, тренер-преподаватель, Спортивный клуб «Олимп».
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/o2WCVdYdnEwflQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Стягайло Екатерина Станиславна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: e.kapustina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>