<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			11.10.2024
		</p>
		<h2 class="NewsPage-title">
			Итоги осенних профильных смен по направлению «Наука»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage83.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одарённых детей «Персей» с 26 сентября по 9 октября прошли
				профильные смены по направлениям «Агропромышленные и биотехнологии», «Беспилотные авиационные системы» и
				«Подготовка к программе «Сириус ИИ». В сменах приняли участие 137 школьников из 21 района Иркутской области.
				Программа была насыщенной и разнообразной, охватывающая ключевые направления науки и технологий.
			</p>
			<p class="NewsPage-sub-title">
				<b>Агропромышленные и биотехнологии</b>
			</p>
			<p class="NewsPage-sub-title">
				На профильной смене по агропромышленным и биотехнологиям учащиеся познакомились с новейшими технологиями в
				сельском хозяйстве, углубили знания в области агробизнеса и разработали собственные проекты. Их работы включали
				планы по внедрению инноваций в сельское хозяйство на базе своих школ. Программа позволила участникам не только
				овладеть современными методами ведения сельскохозяйственных процессов, но и обменяться опытом с другими
				участниками смены.
			</p>
			<p class="NewsPage-sub-title">
				Как рассказали преподаватели: программа смены включала три направления: Биотехнологии – знакомство с передовыми
				технологиями, меняющими сельское хозяйство, Современная агротехника – изучение новейших методов и инструментов в
				агротехнике. Генетика, селекция и районирование – углубленное изучение генетических методов и их применения.
			</p>
			<p class="NewsPage-sub-title">
				– В Образовательном центре «Персей» прошли занятия по различным видам агротехнологий. Ребята познакомились с
				технологиями переработки сельскохозяйственной продукции и стандартизации, а также основами животноводства и
				ветеринарией. В рамках этой программы мы помогли учащимся выбрать свою будущую профессию, показали возможности
				сельскохозяйственного сектора региона. В нашем регионе много предприятий агропромышленного комплекса, которые
				активно развиваются и нуждаются в квалифицированных специалистах. Это направление не только перспективно с точки
				зрения трудоустройства, но и важно для устойчивого развития сельского хозяйства в Иркутской области, – пояснила
				Ольга Ивонина, преподаватель смены, кандидат сельскохозяйственных наук, доцент зоотехнии и технологии
				переработки сельскохозяйственной продукции Иркутского аграрного университета им. А.А. Ежевского
			</p>
			<p class="NewsPage-sub-title">
				Одной из важных компетенций, которые освоили участники программы, стала предпринимательская деятельность.
				Учащиеся не только изучили основы создания и ведения агробизнеса, но и разработали собственные бизнес-планы,
				ориентированные на сельскохозяйственную отрасль региона. Они учились анализировать рынок, определять потребности
				потребителей и выбирать наиболее эффективные стратегии для внедрения своих проектов. Это позволило им получить
				ценные навыки, которые помогут в будущем не только в карьере, но и в развитии собственного дела.
			</p>
			<p class="NewsPage-sub-title">
				Свои бизнес-проекты ребята представили на итоговой защите. Так школьники разработали несколько интересных
				решений: «Воздушный пастух» — проект, созданный для упрощения работы пастуха с помощью дрона. Он предназначен
				для помощи фермерам, населению и сельскохозяйственным организациям, занимающимся выпасом скота на пастбищах. В
				случае критических ситуаций (например, потеря животного), дрон помогает распознавать местность по фотографиям и
				подавать сигналы. «ГроуБокс» — система для выращивания ягод и овощей в домашних условиях. Это гидропонная
				установка, которая адаптируется под нужды клиента и отличается простотой в обслуживании. Также ребята
				исследовали влияние фосфорных удобрений на рост и развитие томатов. В ходе анализа они выявили оптимальное
				количество удобрений для их эффективного выращивания культур.
			</p>
			<p class="NewsPage-sub-title">
				<b>Беспилотные и авиационные системы</b>
			</p>
			<p class="NewsPage-sub-title">
				Участники профильной смены «Беспилотные и авиационные системы» освоили управление различными типами дронов,
				включая модели самолетного и вертолетного типов. С помощью симуляторов ребята тренировали навыки полета, а также
				запускали дроны в реальных условиях. Практические занятия позволили им лучше понять конструкцию беспилотных
				систем и изучить принципы работы авиационных систем, что стало отличной подготовкой для будущих инженеров и
				техников.
			</p>
			<p class="NewsPage-sub-title">
				– Нас разделили на две группы: одна изучала самолёты, а другая — дроны. Мы изучали их конструкцию, принципы
				управления и способы применения в различных сферах. Особенно запомнились практические занятия — мы тренировались
				на симуляторах и запускали дроны в реальных условиях. Это был бесценный опыт, который, уверен, пригодится мне в
				будущем, – поделился Василий Абрамов, ученик Марковской школы № 2.
			</p>
			<p class="NewsPage-sub-title">
				Итогом программы стали соревнования по дрон-рейсингу и пайке летательных аппаратов. Участники не только
				соревновались в скорости и точности управления дронами на специально оборудованных трассах, но и
				продемонстрировали навыки пайки и сборки ключевых компонентов беспилотников. Это позволило ребятам глубже понять
				устройство дронов, применить теоретические знания на практике.
			</p>
			<p class="NewsPage-sub-title">
				<b>Подготовка к программе «Сириус ИИ»</b>
			</p>
			<p class="NewsPage-sub-title">
				«Сириус ИИ» – это научно-технологическая программа проектных команд, где участники представят решение проектной
				задачи в области искусственного интеллекта и смежных дисциплин заказчиками которых выступают ведущие компании
				страны.
			</p>
			<p class="NewsPage-sub-title">
				Смена «Подготовка к программе «Сириус ИИ» была направлена на обучение современным технологиям и
				программированию. Школьники занимались созданием и анализом датасетов, разработкой алгоритмов машинного обучения
				и нейросетей. Работая с библиотеками Python и инструментами data engineering, они решали задачи осеннего сезона
				программы «Сириус ИИ». Программа позволила учащимся улучшить свои навыки программирования, работы в команде и
				проектного мышления.
			</p>
			<p class="NewsPage-sub-title">
				Стажер-исследователь в Молодежной лаборатории искусственного интеллекта и анализа данных Института динамики и
				теории управления В.М. Матросова Сибирского отделения РАН Кирилл Тобола и Программист компании «Спутник»,
				преподаватель образовательных программ для школьников по машинному обучению и работе с нейросетями Алексей Быков
				рассказали чем занимались школьники во время обучения:
			</p>
			<p class="NewsPage-sub-title">
				– Мы разрабатывали различные приложения, которые позже планируем реализовать на смене в «Сириусе». Каждый
				участник отвечал за свою задачу: кто-то писал код, кто-то готовил презентации. На этой смене мы дали ребятам
				базовые знания по искусственному интеллекту, которые обязательно пригодятся им, если они захотят продолжить
				изучение этой области. Полученные навыки окажутся полезными в будущем, особенно если они решат связать свою
				карьеру с программированием, нейронными сетями и машинным обучением.
			</p>
			<p class="NewsPage-sub-title">
				На смене участники подготовили восемь проектных решений для задач третьего сезона научно-технологической
				программы «Сириус.ИИ». Ребята разработали: AI-ассистента отельера для создания описания отеля (задача от МТС),
				приложение для чтения книг с AI-ассистентом для Сбера, AI-ассистента для работы с научной литературой для
				компании «Биокард» и интеллектуального помощника для создания учебных материалов для Университета «Сириус».
			</p>
			<p class="NewsPage-sub-title">
				Эта программа углубила знания участников в области ИИ и машинного обучения, а также развила их коммуникативные
				навыки и умение работать в команде при разработке решений в сжатые сроки. Теперь их ждут два этапа отбора в
				программу «Сириус.ИИ».
			</p>
			<p class="NewsPage-sub-title">
				– Эти профильные смены дают школьникам уникальные возможности углубить свои знания и поработать с современными
				технологиями. Благодаря таким образовательным программам мы раскрываем потенциал наших детей и готовим их к
				участию в ключевых научных и технологических проектах, – отметил директор Образовательного центра «Персей»
				Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				<b>Для справки:</b> в осенних научных профильных сменах приняли участие 137 школьников из Саянска, Нижнеудинска,
				Усть-Кута, Байкальска, Усолье-Сибирского, Тулуна, Иркутска, Братска, Ангарска, Усть-Илимска, Киренска,
				Нижнеилимского, Тайшетского, Аларского, Усольского, Тулунского, Иркутского, Братского, Боханского,
				Усть-Илимского, Черемховского, Ангарского, Эхирит-Булагатского районов. Следующие программы по науке пройдут уже
				в ноябре. За анонсами старта отбора на программы следите в сообществе Образовательного центра «Персей»
				Вконтакте: <a class="NewsPage-link" href="https://vk.com/perseusirk">https://vk.com/perseusirk</a>
			</p>

		</div>
	</div>
</template>
