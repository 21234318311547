<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			02.10.2024
		</p>
		<h2 class="NewsPage-title">
			В Иркутской области проходит школьный этап Всероссийской олимпиады школьников
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage82.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Приангарье начался школьный этап Всероссийской олимпиады школьников, который продлится до конца октября.
				Учащиеся образовательных учреждений региона примут участие в состязаниях по 23 общеобразовательным предметам:
				русский, иностранный язык (английский, немецкий, французский, испанский, китайский), экология, география,
				литература, история, обществознание, экономика, право, искусство (мировая художественная культура), физическая
				культура, труд (технология), основы безопасности и защиты Родины, которые пройдут в очном формате. По шести
				предметам: математика, информатика, химия, биология, астрономия и физика — олимпиады проводятся в онлайн-формате
				на платформе «Сириус. Курсы».
			</p>
			<p class="NewsPage-sub-title">
				Олимпиада призвана выявить и поддержать талантливых школьников, способствовать развитию их интереса к предметам
				и формированию интеллектуального потенциала.
			</p>
			<p class="NewsPage-sub-title">
				Директор Образовательного центра «Персей» Алексей Шестаков отметил важность этого мероприятия для региона:
			</p>
			<p class="NewsPage-sub-title">
				«Всероссийская олимпиада школьников — это уникальная возможность для учащихся проявить свои знания и
				способности, а также получить новый опыт и мотивацию к дальнейшему обучению. Уверен, что и в этом году ребята
				проявят себя достойно, а участие в олимпиаде станет для них важным шагом на пути к профессиональному и
				личностному росту».
			</p>
			<p class="NewsPage-sub-title">
				В школьном этапе примут участие более 100 тысяч учащихся Иркутской области. Победители и призеры, набравшие
				необходимое количество баллов смогут принять участие в муниципальном этапе, который пройдет в ноябре.
			</p>
			<p class="NewsPage-sub-title">
				Для подготовки к муниципальному этапу олимпиады Образовательный центр «Персей» проводит курсы в дистанционном
				формате. В настоящее время доступны курсы по праву, истории, математике, основам безопасности и защиты Родины,
				немецкому и английскому языку. Регистрация доступна на сайте: <a class="NewsPage-link"
					href="https://perseusirk.ru/RemoteBlock">https://perseusirk.ru/RemoteBlock</a>
			</p>
			<p class="NewsPage-sub-title">
				Для справки: Всероссийская олимпиада школьников проводится ежегодно в четыре этапа — школьный, муниципальный,
				региональный и заключительный. Дипломы победителей и призеров заключительного этапа ВсОШ в течение четырех лет
				дают право на поступление в любой вуз страны без вступительных экзаменов по соответствующему профилю.
			</p>

		</div>
	</div>
</template>
