<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Актуальные программы
		</h2>

		<h4 class="MainNavigator-title">При выборе общеразвивающей программы или мероприятия Образовательного центра
			"Персей" необходимо пройти регистрацию на сайте: Навигатор дополнительного образования детей Иркутской области
			<a class="MainOlympics-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/"
				target="_blank">38-навигатор.дети</a>
		</h4>
		<h4 class="MainNavigator-title">
			<a class="MainOlympics-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE-%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8-%D0%BD%D0%B0%D0%B2%D0%B8%D0%B3%D0%B0%D1%82%D0%BE%D1%80.pdf"
				target="_blank">
				Инструкция по регистрации
			</a>
		</h4>
		<div class="grid-container">


			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage209' })">
						<events-card209></events-card209>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage208' })">
						<events-card208></events-card208>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage207' })">
						<events-card207></events-card207>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage206' })">
						<events-card206></events-card206>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage205' })">
						<events-card205></events-card205>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage204' })">
						<events-card204></events-card204>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage203' })">
						<events-card203></events-card203>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage202' })">
						<events-card202></events-card202>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage201' })">
						<events-card201></events-card201>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage200' })">
						<events-card200></events-card200>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage199' })">
						<events-card199></events-card199>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage198' })">
						<events-card198></events-card198>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage197' })">
						<events-card197></events-card197>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage193' })">
						<events-card193></events-card193>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage192' })">
						<events-card192></events-card192>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage190' })">
						<events-card190></events-card190>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage189' })">
						<events-card189></events-card189>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage187' })">
						<events-card187></events-card187>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage183' })">
						<events-card183></events-card183>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage174' })">
						<events-card174></events-card174>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage176' })">
						<events-card176></events-card176>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage175' })">
						<events-card175></events-card175>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage148' })">
						<events-card148></events-card148>
					</a>
				</div>
			</div>

		</div>

		<!-- <router-link to="/ProfileBlock" class="Events-btn temp-btn">
				Все программы
		</router-link> -->
	</div>
</template>

<script>
import EventsCard148 from '../EventsCard/EventsCard148';
import EventsCard174 from '../EventsCard/EventsCard174';
import EventsCard175 from '../EventsCard/EventsCard175';
import EventsCard176 from '../EventsCard/EventsCard176';
import EventsCard183 from '../EventsCard/EventsCard183';
import EventsCard187 from '../EventsCard/EventsCard187';
import EventsCard189 from '../EventsCard/EventsCard189';
import EventsCard190 from '../EventsCard/EventsCard190';
import EventsCard192 from '../EventsCard/EventsCard192';
import EventsCard193 from '../EventsCard/EventsCard193';
import EventsCard197 from '../EventsCard/EventsCard197';
import EventsCard198 from '../EventsCard/EventsCard198';
import EventsCard199 from '../EventsCard/EventsCard199';
import EventsCard200 from '../EventsCard/EventsCard200';
import EventsCard201 from '../EventsCard/EventsCard201';
import EventsCard202 from '../EventsCard/EventsCard202';
import EventsCard203 from '../EventsCard/EventsCard203';
import EventsCard204 from '../EventsCard/EventsCard204';
import EventsCard205 from '../EventsCard/EventsCard205';
import EventsCard206 from '../EventsCard/EventsCard206';
import EventsCard207 from '../EventsCard/EventsCard207';
import EventsCard208 from '../EventsCard/EventsCard208';
import EventsCard209 from '../EventsCard/EventsCard209';


	export default {
		components: {
			EventsCard148,
			EventsCard174,
			EventsCard175,
			EventsCard176,
			EventsCard183,
			EventsCard187,
			EventsCard189,
			EventsCard190,
			EventsCard192,
			EventsCard193,
			EventsCard197,
			EventsCard198,
			EventsCard199,
			EventsCard200,
			EventsCard201,
			EventsCard202,
			EventsCard203,
			EventsCard204,
			EventsCard205,
			EventsCard206,
			EventsCard207,
			EventsCard208,
			EventsCard209,
		}
	}
</script>
