<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Подготовка к программе «Сириус.ИИ»
		</h2>
		<p class="EventsPage-date">
			с 26 сентября по 9 октября 2024
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=187" target="_blank">
			Регистрация на смену
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25541-programma-podgotovka-k-programme-sirius-ii" target="_blank">
			Регистрация в навигаторе
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			На профильной смене будем работать над проектными задачами программы Сириус.ИИ осеннего сезона - 2024. А именно,
			работать с библиотеками Python, инструментами data engineering и data analytics, создавать датасеты и алгоритмы
			машинного обучения, программировать нейросети и упаковывать результаты в проект. В целом, программа повысит
			компетенции в области программирования и командной проектной работы.
			Подробнее в тематическом плане
		</p>

		<div class="AllRussian-img-box">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/BigChallenges/TPSiriusII.webp" alt="План">
		</div>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Михайлов Андрей Анатольевич, кандидат технических наук, заведующий молодежной лабораторией искусственного
				интеллекта, обработки и анализа данных Федерального государственного бюджетного учреждения науки «Институт
				динамики систем и теории управления имени В.М. Матросова» Сибирского отделения Российской академии наук.
			</li>
			<li class="JuniorProfi-items">
				Шведина Светлана Александровна, кандидат экономических наук, доцент, заведующий отделом проектной деятельности
				Образовательного центра «Персей».
			</li>
			<li class="JuniorProfi-items">
				Тобола Кирилл Владимирович, стажер-исследователь молодежной лаборатории искусственного интеллекта, обработки и
				анализа данных Федерального государственного бюджетного учреждения науки «Институт динамики систем и теории
				управления имени В.М. Матросова» Сибирского отделения Российской академии наук.
			</li>
			<li class="JuniorProfi-items">
				Быков Алексей Евгеньевич, программист ООО "Спутник", преподаватель образовательных программ для школьников по
				машинному обучению и работе с нейросетями.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Регистрация на смену на платформе Персея (кнопка выше). ЗАПОЛНЕНИЕ АНКЕТ ОБЯЗАТЕЛЬНО!
			</li>
			<li class="JuniorProfi-items">
				Отбор. На смену смогут попасть только те, кто до 15 сентября успешно завершить минимум 2 из 4 курсов
				Сириус.Курсы <a style="color:blue"
					href="https://edu.sirius.online/ai-navigator/">https://edu.sirius.online/ai-navigator/</a>:
			</li>
			<li class="JuniorProfi-items">
				Знакомство с искусственным интеллектом
			</li>
			<li class="JuniorProfi-items">
				Введение в алгоритмы: реализация на языке Python
			</li>
			<li class="JuniorProfi-items">
				Анализ данных на Python
			</li>
			<li class="JuniorProfi-items">
				Введение в машинное обучение
			</li>
			<li class="JuniorProfi-items">
				Регистрация в Навигаторе дополнительного образования Иркутской области. РЕГИСТРАЦИЯ ОБЯЗАТЕЛЬНА! (кнопка выше)
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Профильная смена состоится с 26 сентября по 9 октября 2024 года на бесплатной основе в Образовательном центре
			«Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Аджалова Джулай Витальевна</li>
			<li class="EventsPage-number">Аджалова Хазан</li>
			<li class="EventsPage-number">Бражников Александр Иванович</li>
			<li class="EventsPage-number">Высоцков Егор Алексеевич</li>
			<li class="EventsPage-number">Гаврилов Илья Николаевич</li>
			<li class="EventsPage-number">Димов Никита Алексеевич</li>
			<li class="EventsPage-number">Дубровина Ольга Владимировна</li>
			<li class="EventsPage-number">Еремин Игорь Иванович</li>
			<li class="EventsPage-number">Зайцев Арсений Максимович</li>
			<li class="EventsPage-number">Золотарев Арсений Дмитриевич</li>
			<li class="EventsPage-number">Иванов Андрей Денисович</li>
			<li class="EventsPage-number">Карро Дмитрий Николаевич</li>
			<li class="EventsPage-number">Кирсанова Анна Алексеевна</li>
			<li class="EventsPage-number">Косьяненко Дарья Дмитриевна</li>
			<li class="EventsPage-number">Кузнецов Олег Владиславович</li>
			<li class="EventsPage-number">Кузьменко Артём Сергеевич</li>
			<li class="EventsPage-number">Куклина Анастасия Ярославовна</li>
			<li class="EventsPage-number">Кулаковский Вячеслав Сергеевич</li>
			<li class="EventsPage-number">Ладина София Игоревна</li>
			<li class="EventsPage-number">Луковников Никита Сергеевич</li>
			<li class="EventsPage-number">Микрюкова Зоя Сергеевн</li>
			<li class="EventsPage-number">Михеева Полина Артёмовна</li>
			<li class="EventsPage-number">Олиневич Денис Валериевич</li>
			<li class="EventsPage-number">Пакилев Дмитрий Андреевич</li>
			<li class="EventsPage-number">Першуков Даниил Олегович</li>
			<li class="EventsPage-number">Скачков Георгий Игоревич</li>
			<li class="EventsPage-number">Суворов Павел Алексеевич</li>
			<li class="EventsPage-number">Торопов Кирилл Викторович</li>
			<li class="EventsPage-number">Трубников Дмитрий Викторович</li>
			<li class="EventsPage-number">Устинов Александр Дмитриевич</li>
			<li class="EventsPage-number">Филькин Никита Олегович</li>
			<li class="EventsPage-number">Хачатурян Ева Валерьевна</li>
			<li class="EventsPage-number">Хуззятов Георгий Николаевич</li>
			<li class="EventsPage-number">Шиповский Александр Юрьевич</li>
			<li class="EventsPage-number">Шишкин Денис Вячеславович</li>
			<li class="EventsPage-number">Юдин Владислав Николаевич</li>
			<li class="EventsPage-number">Южиков Максим Константинович</li>
			<li class="EventsPage-number">Юрин Дмитрий Александрович</li>
		</ol>
		<br>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 19 сентября 2024 г. в 18.00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1724995273">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/mm6sRIzemW4ZgQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Шведина Светлана Александровна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: s.shvedina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>