<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Оркестровое духовое исполнительство»
		</h2>
		<p class="EventsPage-date">
			с 16 по 29 августа 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=178" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25354-programma-orkestrovoe-dukhovoe-ispolnitelstvo " target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Образовательный центр «Персей» проводит профильную смену «Оркестровое духовое исполнительство» для учащихся от 10
			до 17 лет, обладающих основными музыкальными способностями (слух, ритм, память), исполнительскими навыками игры на
			духовых или ударных инструментах, а также начальными навыками игры в ансамбле.
		</p>

		<p class="EventsPage-text">
			Программа «Оркестровое духовое исполнительство» ориентирована на развитие мотивации учащихся к познанию через
			создание условий для раскрытия творческого потенциала в составе оркестра, выявление одаренных детей в области
			музыкального искусства, развитие умений и навыков игры на духовых инструментах в ансамбле и в оркестре,
			позволяющих исполнять музыкальные произведения в соответствии с необходимым уровнем музыкальной грамотности и
			стилевыми традициями, а также, приобщение детей к сценическим формам духового оркестрового исполнительства.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Сорвин Александр Валерьевич, преподаватель по классу трубы государственного нетипового общеобразовательного
				бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска», дирижер
				Образцового детского духового оркестра «Серебряные трубы»;
			</li>
			<li class="JuniorProfi-items">
				Портяная Анастасия Александровна, преподаватель по классу саксофона государственного нетипового
				общеобразовательного бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г.
				Иркутска»;
			</li>
			<li class="JuniorProfi-items">
				Мархаев Кирилл Андреевич, преподаватель ГОБУДО Иркутская областная ДШИ;
			</li>
			<li class="JuniorProfi-items">
				Сергеев Олег Дмитриевич, преподаватель государственного нетипового общеобразовательного бюджетного учреждения
				Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска»;
			</li>
			<li class="JuniorProfi-items">
				Мешин Антон Андреевич, преподаватель государственного нетипового общеобразовательного бюджетного учреждения
				Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска»;
			</li>
			<li class="JuniorProfi-items">
				Федоренко Владислав Валерьевич, преподаватель ГБПОУ Иркутский областной музыкальный колледж им. Ф.Шопена;
			</li>
			<li class="JuniorProfi-items">
				Фролов Семён Александрович, преподаватель преподаватель государственного нетипового общеобразовательного
				бюджетного учреждения Иркутской области «Школа-интернат музыкантских воспитанников г. Иркутска».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Учащимся, обладающим исполнительскими навыками игры на духовых инструментах, а также начальными навыками игры
				в ансамбле, необходимо обязательно зарегистрироваться до 6 августа 2024 года в системе Навигатор дополнительного
				образования Иркутской области по ссылке:
				https://р38.навигатор.дети/program/25354-programma-orkestrovoe-dukhovoe-ispolnitelstvo
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету участника на платформе электронной информационно-образовательной среды
				Образовательного центра «Персей» по ссылке: https://moodle.perseusirk.ru/course/view.php?id=178
			</li>
			<li class="JuniorProfi-items">
				где необходимо прикрепить:
			</li>
			<li class="JuniorProfi-items">
				портфолио с Дипломами за участие в конкурсах регионального и всероссийского уровня (2023-2024 год);
			</li>
			<li class="JuniorProfi-items">
				рекомендательное письмо от образовательного учреждения об участии в профильной смене.
			</li>
			<li class="JuniorProfi-items">
				Каждый участник смены должен иметь личный музыкальный инструмент для занятий.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Адамов Владимир Владимирович</li>
			<li class="EventsPage-number">Акимова Анастасия Александровна</li>
			<li class="EventsPage-number">Алексеев Тимофей Николаевич</li>
			<li class="EventsPage-number">Алексеенко Анастасия Максимовна</li>
			<li class="EventsPage-number">Андюбек Сергей Михайлович</li>
			<li class="EventsPage-number">Арестов Степан Михайлович</li>
			<li class="EventsPage-number">Баранова Антонина Сергеевна</li>
			<li class="EventsPage-number">Барышников Марк Владимирович</li>
			<li class="EventsPage-number">Белоусова Василиса Алексеевна</li>
			<li class="EventsPage-number">Бобрышев Кирилл Алексеевич</li>
			<li class="EventsPage-number">Бывальцев Владислав Сергеевич</li>
			<li class="EventsPage-number">Вахрамеева Екатерина Олеговна</li>
			<li class="EventsPage-number">Вахрушев Виктор Вячеславович</li>
			<li class="EventsPage-number">Вашукевич Валерий Юрьевич</li>
			<li class="EventsPage-number">Власова Мария Евгеньевна</li>
			<li class="EventsPage-number">Володин Алексей Арсеньевич</li>
			<li class="EventsPage-number">Володин Вячеслав Арсеньевич</li>
			<li class="EventsPage-number">Врубель Таисия Максимовна</li>
			<li class="EventsPage-number">Гимишян Армен Арсенович</li>
			<li class="EventsPage-number">Гладкий Ростислав Игоревич</li>
			<li class="EventsPage-number">Горячов Иван Евгеньевич</li>
			<li class="EventsPage-number">Гудин Петр Григорьевич</li>
			<li class="EventsPage-number">Дорофеева Амелия Романовна</li>
			<li class="EventsPage-number">Дорофеева Кира Романовна</li>
			<li class="EventsPage-number">Дружинин Владислав Алексеевич</li>
			<li class="EventsPage-number">Дьячков Иннокентий Антонович</li>
			<li class="EventsPage-number">Евдокимов Михаил Сергеевич</li>
			<li class="EventsPage-number">Евтягин Матвей Александрович</li>
			<li class="EventsPage-number">Е́лшина Евангелина Александровна</li>
			<li class="EventsPage-number">Желтова София Станиславовна</li>
			<li class="EventsPage-number">Захарченко Валерия Александровна</li>
			<li class="EventsPage-number">Золотуева Екатерина Сергеевна</li>
			<li class="EventsPage-number">Иванюха Глеб Владимирович</li>
			<li class="EventsPage-number">Ишенин Денис Юрьевич</li>
			<li class="EventsPage-number">Канищев Яков Андреевич</li>
			<li class="EventsPage-number">Капранов Антон Романович</li>
			<li class="EventsPage-number">Карандин Илья Андреевич</li>
			<li class="EventsPage-number">Ковалёв Андрей Александрович</li>
			<li class="EventsPage-number">Козлова Олеся Денисовна</li>
			<li class="EventsPage-number">Комаров Алексей Васильевич</li>
			<li class="EventsPage-number">Королькова Дарья Петровна</li>
			<li class="EventsPage-number">Короткова Алиса Андреевна</li>
			<li class="EventsPage-number">Корсуновская Ольга Михайловна</li>
			<li class="EventsPage-number">Кошелева Мия Дмитриевна</li>
			<li class="EventsPage-number">Кошкин Роман Андреевич</li>
			<li class="EventsPage-number">Кузина Елизавета Константиновна</li>
			<li class="EventsPage-number">Кулакова Елизавета Романовна</li>
			<li class="EventsPage-number">Ларионов Богдан Алексеевич</li>
			<li class="EventsPage-number">Лисин Максим Павлович</li>
			<li class="EventsPage-number">Лошак Дмитрий Константинович</li>
			<li class="EventsPage-number">Лукьянов Сергей Семёнович</li>
			<li class="EventsPage-number">Малов Пётр Дмитриевич</li>
			<li class="EventsPage-number">Маслюков Денис Васильевич</li>
			<li class="EventsPage-number">Масько Елизавета Павловна</li>
			<li class="EventsPage-number">Матченко Михаил Александрович</li>
			<li class="EventsPage-number">Машукова Вера Владимировна</li>
			<li class="EventsPage-number">Медведев Георг</li>
			<li class="EventsPage-number">Минкин Егор Александрович</li>
			<li class="EventsPage-number">Мисько Иван Романович</li>
			<li class="EventsPage-number">Михайлов Серафим Ильич</li>
			<li class="EventsPage-number">Некряч Филипп Олегович</li>
			<li class="EventsPage-number">Новиков Артем Русланович</li>
			<li class="EventsPage-number">Павлов Константин Евгеньевич</li>
			<li class="EventsPage-number">Петров Егор Алексеевич</li>
			<li class="EventsPage-number">Петров Фёдор Артемович</li>
			<li class="EventsPage-number">Пихаева Виктория Александровна</li>
			<li class="EventsPage-number">Поздняк Александр Иванович</li>
			<li class="EventsPage-number">Попова Валентина Михайловна</li>
			<li class="EventsPage-number">Пшеничников Савва Артёмович</li>
			<li class="EventsPage-number">Реченская Мария Дмитриевна</li>
			<li class="EventsPage-number">Рубан Татьяна Александровна</li>
			<li class="EventsPage-number">Самоходкина Ульяна Алексеевна</li>
			<li class="EventsPage-number">Самылин Даниил Дмитриевич</li>
			<li class="EventsPage-number">Смолянинова Василиса Дмитриевна</li>
			<li class="EventsPage-number">Соболев Артём Олегович</li>
			<li class="EventsPage-number">Стахова Ева Владимировна</li>
			<li class="EventsPage-number">Стахеев Семён Александрович</li>
			<li class="EventsPage-number">Стексова Дарья Федоровна</li>
			<li class="EventsPage-number">Топольский Всеволод Викторович</li>
			<li class="EventsPage-number">Травников Андрей Романович</li>
			<li class="EventsPage-number">Травникова Валерия Романовна</li>
			<li class="EventsPage-number">Третьяков Владислав Анатольевич</li>
			<li class="EventsPage-number">Трудов Владислав Валерьевич</li>
			<li class="EventsPage-number">Филипенко Диана Сергеевна</li>
			<li class="EventsPage-number">Филиппов Павел Игоревич</li>
			<li class="EventsPage-number">Хиревич Егор Максимович</li>
			<li class="EventsPage-number">Хоборков Сергей Александрович</li>
			<li class="EventsPage-number">Хуззятов Павел Николаевич</li>
			<li class="EventsPage-number">Цевенов Никита Георгиевич</li>
			<li class="EventsPage-number">Цоня Мария Алексеевна</li>
			<li class="EventsPage-number">Чибиряк Андрей Вадимович</li>
			<li class="EventsPage-number">Чуданов Назар Станиславович</li>
			<li class="EventsPage-number">Шалдушкеев Сырен-Доржа Александрович</li>
			<li class="EventsPage-number">Шевцов Артемий Евгеньевич</li>
			<li class="EventsPage-number">Шевченко София Дмитриевна</li>
			<li class="EventsPage-number">Шелегова Екатерина Юрьевна</li>
			<li class="EventsPage-number">Шинкарёв Михаил Викторович</li>
			<li class="EventsPage-number">Шкляр Марк Сергеевич</li>
			<li class="EventsPage-number">Шнырев Павел Евгеньевич</li>
			<li class="EventsPage-number">Шумихин Глеб Анатольевич</li>
			<li class="EventsPage-number">Яковлев Ростислав Дмитриевич</li>
			<li class="EventsPage-number">Ярыгина Софья Максимовна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>