<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Программирование на языке Python»
		</h2>
		<p class="EventsPage-date">
			с 15 октября по 25 декабря 2024 года
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->
		<!-- <a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a> -->
		<hr>

		<p class="EventsPage-text">
			Дистанционный курс «Программирование на языке Python» поможет понять принципы построения программ на языке Python,
			его синтаксис, разработку консольных приложений.
		</p>

		<p class="EventsPage-text">
			По итогам учащиеся будут уметь писать программный код, решать широкий спектр задач на языке программирования
			Python; знать основные термины, использующихся в среде Python-программистов, сам язык программирования Python как
			средство разработки прикладного программного обеспечения, его базовые типы и структуры данных, основы
			алгоритмизации.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Скворцов Даниил Евгеньевич, программист кафедры обогащения полезных ископаемых и охраны окружающей среды ФГБОУ
				ВО «ИРНИТУ»
			</li>
		</ul>

		<!-- <p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">

			</li>
			<li class="JuniorProfi-items">

			</li>
			<li class="JuniorProfi-items">

			</li>
		</ul>
		<hr> -->

		<!-- <p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number"></li>

		</ol>
		<br>
		<hr> -->

		<!-- <p class="EventsPage-text">
			<a style="color:blue" href="">ссылка на подключение</a>
		</p>
		<hr> -->

		<!-- <p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<!-- <p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<!-- <p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul> -->

	</div>
</template>