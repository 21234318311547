<template>
	<div class="OlympicPage _cover">
		<h2 class="OlympicPage-title _cover-title">
			Региональная олимпиада для обучающихся начальных классов «Олимпик»<br> 2024 г.
		</h2>

		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/svg/Олимпик.webp" alt="Логотип" />
		</div>

		<p class="JuniorProfi-sub-title">
			Цели олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				развитие познавательной активности младших школьников, их мотивация к глубокому изучению общеобразовательных
				предметов,
			</li>
			<li class="JuniorProfi-items">
				выявление и поддержка одаренных и высокомотивированных обучающихся начальных классов.
			</li>
			<li class="JuniorProfi-items">
				подготовка обучающихся начальных классов к участию в региональных, всероссийских и международных олимпиадах и
				конкурсах средней и старшей ступени обучения.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Возраст обучающихся:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				к участию приглашаются школьники 3 и 4 классов начальной школы.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%B4%D0%BB%D1%8F-%D0%9C%D0%9E%D0%A3%D0%9E-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA.pdf"
					target="_blank">
					Письмо для МОУО Олимпик
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%BA-2025.pdf"
					target="_blank">
					Положение Олимпик 2025
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			График олимпиады:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Регистрация на олимпиаду - с 15 октября до 16 ноября.
			</li>
			<li class="JuniorProfi-items">
				Тур по предмету «Математика» – 21 ноября,
			</li>
			<li class="JuniorProfi-items">
				Тур по предмету «Русский язык» – 26 ноября,
			</li>
			<li class="JuniorProfi-items">
				Тур по предмету «Окружающий мир» – 28 ноября.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Как зарегистрироваться на олимпиаду:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зайти на сайт проведения олимпиады: <a style="color:blue"
					href="eor.vserosperseusirk.ru">eor.vserosperseusirk.ru</a>. Скачать приложение на сайте, пройти регистрацию.
				После этого обязательно указать класс участия в разделе «Личные данные».
			</li>
			<li class="JuniorProfi-items">
				Дополнительно зарегистрироваться и подать заявку на сайте «Навигатор»: <a style="color:blue"
					href="р38.навигатор.дети">р38.навигатор.дети</a>
			</li>
			<li class="marker-2">
				Русский язык: <a style="color:blue"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1742/?date=2024-09-20">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1742/?date=2024-09-20</a>
			</li>
			<li class="marker-2">
				Математика: <a style="color:blue"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1741/?date=2024-09-20">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1741/?date=2024-09-20</a>
			</li>
			<li class="marker-2">
				Окружающий мир: <a style="color:blue"
					href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1743/?date=2024-09-20">https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/activity/1743/?date=2024-09-20</a>
			</li>
		</ul>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE-%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8-%D0%BD%D0%B0%D0%B2%D0%B8%D0%B3%D0%B0%D1%82%D0%BE%D1%80.pdf"
					target="_blank">
					Инструкция навигатор
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Как участвовать:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				По графику олимпиады в приложении сайта <a style="color:blue"
					href="eor.vserosperseusirk.ru">eor.vserosperseusirk.ru</a> зайти в раздел
				«Тесты» и выполнить олимпиадные
				задания.
			</li>
			<li class="JuniorProfi-items">
				Время выполнения задания - 60 минут.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Итоги олимпиады
		</p>
		<p class="EventsPage-text" style="margin: 0;">
			Итоги олимпиады будут опубликованы на сайте <a style="color:blue" href="perseusirk.ru">perseusirk.ru</a>
			до 30 декабря.
		</p>


		<p class="JuniorProfi-sub-title">
			Контакты
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

		<h2 class="OlympicPage-title _cover-title">
			Желаем успехов!
		</h2>

	</div>

	<!-- Архив -->
	<ArchiveOlympic />

</template>

<script>
import ArchiveOlympic from '@/components/ArchiveOlympic.vue';

export default {
	components: {
		ArchiveOlympic,
	}
}

</script>

<style>
ul.marker-2 {
	list-style: none;
	margin-bottom: 5px;
}

ul li.marker-2:before {
	content: "—";
	position: relative;
	left: -5px;
}

.marker-2 {
	margin: 20px 0 0 30px
}
</style>