<template>
	<div class="Preparation _cover">
		<h2 class="Preparation-title _cover-title">
			«Подготовка к олимпиаде»
		</h2>
		<div class="AllRussian-img-box" style="max-width: 500px;">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>
		<div class="AllRussian-btn-box">
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'VsoshPage' })">
				Всероссийская олимпиада школьников
			</a>
		</div>

		<div class="grid-container">
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage203' })">
						<events-card203></events-card203>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage202' })">
						<events-card202></events-card202>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage201' })">
						<events-card201></events-card201>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage200' })">
						<events-card200></events-card200>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage199' })">
						<events-card199></events-card199>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage198' })">
						<events-card198></events-card198>
					</a>
				</div>
			</div>
		</div>


		<h3 class="Preparation-sub-title">
			Задания школьного этапа ВСОШ
		</h3>
		<p class="Preparation-dist">
			На образовательном сайте «Сириус.Курсы» собраны варианты заданий школьного этапа олимпиады.<br>
			Выбирайте класс и предмет, знакомьтесь с вариантами, решайте и отправляйте на проверку.<br>
			Подробнее: <a style="color: blue;" href="https://edu.sirius.online/vos/">https://edu.sirius.online/vos/</a><br>
		</p>

		<h3 class="Preparation-sub-title">
			Задания муниципального этапа ВСОШ
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/4hsZkDvrI2u4Ow" target="_blank">
					английский язык
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/qqigwKnSUTj3qw" target="_blank">
					астрономия
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/EMqFgohfhhpO8Q" target="_blank">
					биология
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/kyJWXtshE5wxkg" target="_blank">
					география
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/0kfvvKA_sfmLUQ" target="_blank">
					информатика и ИКТ
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/0Ci7b1lxH6y-BA" target="_blank">
					искусство
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/CMebYcKQkY2l7w" target="_blank">
					испанский язык
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/zh5jmNM13KTYOA" target="_blank">
					история
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/aKzod5yHGwtcxg" target="_blank">
					китайский язык
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/yEPfLz6kf1UcWA" target="_blank">
					литература
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/xohWSRE-ex5dpQ" target="_blank">
					математика
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/-5BjGdMHPKNAVQ" target="_blank">
					немецкий язык
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/pb5o7U3Juj1Baw" target="_blank">
					ОБЖ
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/DFUfaUy9rp_sag" target="_blank">
					общество
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/0tTmR36WQDYdYA" target="_blank">
					право
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/QqjTy2ziQVkk4g" target="_blank">
					русский язык
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/S84Z35SApKAN_w" target="_blank">
					технология
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/-98fhOsbxix1JQ" target="_blank">
					физика
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/YQ2Qvi6-CI1JjA" target="_blank">
					физическая культура
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/wz9oWybTfO6bmA" target="_blank">
					французский язык
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/w7R5Q2CKRmMYmw" target="_blank">
					химия
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/fgZvZM-e4FZzpA" target="_blank">
					экология
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/3R1zsc9fUpd37Q" target="_blank">
					экономика
				</a>
			</li>
		</ul>

		<h2 class="Preparation-title _cover-title">
			Интервью с призерами заключительного этапа ВСОШ
		</h2>
		<div class="Preparation-iframe-box">
			<iframe class="Preparation-iframe" width="560" height="315"
				src="https://www.youtube.com/embed/6FQpwX4tSos?si=Wlpv04lTj6YUSqgo" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen></iframe>
			<iframe class="Preparation-iframe" width="560" height="315"
				src="https://www.youtube.com/embed/d2LlTjzGK74?si=NIqyeyA6s6iLrQhA" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen></iframe>
		</div>
	</div>
</template>

<script>
import EventsCard198 from '../EventsCard/EventsCard198';
import EventsCard199 from '../EventsCard/EventsCard199';
import EventsCard200 from '../EventsCard/EventsCard200';
import EventsCard201 from '../EventsCard/EventsCard201';
import EventsCard202 from '../EventsCard/EventsCard202';
import EventsCard203 from '../EventsCard/EventsCard203';


	export default {
		components: {
			EventsCard198,
			EventsCard199,
			EventsCard200,
			EventsCard201,
			EventsCard202,
			EventsCard203,
		}
	}
</script>