<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Креативное семиборье»
		</h2>
		<p class="EventsPage-date">
			с 1 по 4 ноября 2024 года
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Креативное семиборье» для учащихся 6-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Изучение шести креативных направлений обусловлена растущим интересом к креативным индустриям и их значением в
			современном мире – дизайна, анимации и 3D-графики, звукорежиссуры, VR и AR, современной электронной музыки, фото-
			и видеопроизводства обеспечивает детей современными навыками, необходимыми для успешной будущей карьеры в
			творческих областях.
		</p>

		<p class="EventsPage-text">
			Программа реализуется через технологию организации «креатив-боев» (интеллектуальных командных соревнований),
			которая способствует развитию как индивидуальных, так и коллективных компетенций у обучающихся. Дети учатся не
			только работать над творческими проектами, но и взаимодействовать в команде, что повышает их коммуникационные
			навыки и умение принимать решения в условиях конкуренции.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Варшавская Яна Рафаэльевна – заведующий Школой креативных индустрий в Молчановке;
			</li>
			<li class="JuniorProfi-items">
				Тучнолобова Татьяна Александровна – педагог студии анимации и 3D-графики Школой креативных индустрий в
				Молчановке;
			</li>
			<li class="JuniorProfi-items">
				Образцов Владислав Вадимович – педагог студии интерактивных цифровых технологий ВР/АР Школой креативных
				индустрий в Молчановке;
			</li>
			<li class="JuniorProfi-items">
				Лужецкая Алина Андреевна – педагог студии дизайна Школой креативных индустрий в Молчановке;
			</li>
			<li class="JuniorProfi-items">
				Ермаченко Роман Сергеевич – педагог студии фото- и видеопроизводства Школой креативных индустрий в Молчановке;
			</li>
			<li class="JuniorProfi-items">
				Чечерина Анна Андреевна - педагог студии звукорежиссуры Школой креативных индустрий в Молчановке.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Записаться на программу «Креативное семиборье» на ресурсе «Навигатор дополнительного образования Иркутской
				области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 1 по 4 ноября 2024 года на бесплатной основе в Образовательном центре «Персей»
				(Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 15 октября сентября 2024 г. в 20.00, <a
				style="color:blue" href="https://pruffme.com/landing/Perseus/tmp1728617958">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>