<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>

		<div class="AllRussian-img-box" style="max-width: 500px;">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>

		<h2 class="EventsPage-title">
			«Олимпиадное право»
		</h2>
		<p class="EventsPage-date">
			21 октября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=199" target="_blank">
			Регистрация
		</a>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Олимпиадное право» для учащихся 7-11-х классов
			общеобразовательных организаций
		</p>

		<p class="EventsPage-text">
			Программа «Олимпиадное право» ориентирована на подготовку учащихся, ранее участвующих в различных олимпиадах по
			праву, в том числе - всероссийской олимпиаде школьников. В программе курса будут представлены особенности
			проведения всероссийской олимпиады школьников по праву в рамках каждого тура, а также формирование у учащихся
			общего представления и понимания специфики заданий Всероссийской олимпиады школьников по праву, а также получение
			ими знаний и опыта по их решению.
		</p>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Ганусенко Ирина Владимировна, доцент кафедры конституционного права и теории права ФГБОУ ВО ИГУ Юридический
				институт кандидат юридических наук.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 21 октября 2024 года на платформе Навигатор дополнительного образования детей Иркутской
				области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 21 октября 2024 года на платформе Moodle, Образовательного центра
				«Персей» <a style="color:blue" href="https://moodle.perseusirk.ru/course/view.php?id=199">ссылка на
					регистрацию</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%BF%D0%BE-%D0%B4%D0%B8%D1%81%D1%82.-%D0%BA%D1%83%D1%80%D1%81%D0%B0%D0%BC-%D0%92%D0%A1%D0%9E%D0%A8.pdf"
					target="_blank">
					Письмо по дист. курсам ВСОШ
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Гавриленко Татьяна Георгиевна
			</li>
			<li class="JuniorProfi-items">
				Непомнящая Екатерина Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Бутакова Светлана Владимировна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

	</div>
</template>