<template>
	<div class="_cover">
		<h2 class="_cover-title">
			Структура и органы управления образовательной организацией
		</h2>
		<div class="StructureAndOrgans-img-box">
			<p class="StructureAndOrgans-disc">
				В соответствии с Уставом Образовательного центра «Персей» единоличным исполнительным органом является директор.
			</p>
			<img loading="lazy" class="StructureAndOrgans-img" src="../assets/img/jpg/Stru.webp"
				alt="Структура и органы управления образовательной организацией">
		</div>
		<hr>
		<h3 class="_cover-title">
			Наименование структурных подразделений
		</h3>
		<p class="StructureAndOrgans-dist">
			Образовательный центр «Персей» имеет в своем составе следующие структурные подразделения (регламентированы
			Положением о структурных подразделениях Образовательного центра «Персей»):
		</p>
		<div class="StructureAndOrgans-table">
			<table>
				<tr>
					<th>№</th>
					<th>Название структурного подразделения</th>
					<th>Локально- нормативный акт</th>
					<th>Место нахождения</th>
					<th>Руководитель, должность</th>
					<th>Ф.И.О</th>
					<th>Email</th>
				</tr>
				<tr>
					<td>1</td>
					<td>Отдел правового кадрового обеспечения</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%BF%D1%80%D0%B0%D0%B2%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE-%D0%B8-%D0%BA%D0%B0%D0%B4%D1%80%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Космачева Анастасия Николаевна</td>
					<td><a href="mailto:a.kosmacheva@perseusirk.ru">a.kosmacheva@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>2</td>
					<td>Отдел информационно-технического
						обеспечения и связей с
						общественностью</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%98%D0%A2%D0%9E-%D0%B8-%D0%A1%D0%9E.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Хранивский Сергей Дмитриевич</td>
					<td><a href="mailto:s.hranivskiy@perseusirk.ru">s.hranivskiy@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>3</td>
					<td>Управление по организационному и
						эксплуатационно-техническому
						обеспечению кампуса ОЦ</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%A3%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9E%D0%A2%D0%AD%D0%9E.pdf"
							target="_blank" download>Положение</a></td>
					<td>Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</td>
					<td>Руководитель управления</td>
					<td>Соболева Татьяна Борисовна</td>
					<td><a href="mailto:t.soboleva@perseusirk.ru">t.soboleva@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>4</td>
					<td>Отдел эксплуатационнотехнического обеспечения</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D1%8D%D0%BA%D1%81%D0%BF%D0%BB%D1%83%D0%B0%D1%82%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE-%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%BD%D0%B8%D1%8F.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Хранивский Игорь Дмитриевич</td>
					<td><a href="mailto:i.hranivskiy@perseusirk.ru">i.hranivskiy@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>5</td>
					<td>Учебно-методический отдел</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D1%83%D1%87%D0%B5%D0%B1%D0%BD%D0%BE-%D0%BC%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%BC-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Соболева Елена Владимировна</td>
					<td><a href="mailto:e.soboleva@perseusirk.ru">e.soboleva@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>6</td>
					<td>Отдел развития образовательных программ по направлению «Наука»</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%9D%D0%B0%D1%83%D0%BA%D0%B0.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Толстихина Татьяна Павловна</td>
					<td><a href="mailto:n.tolstihina@perseusirk.ru">n.tolstihina@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>7</td>
					<td>Отдел развития образовательных программ по направлению «Искусство»
					</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Сыроватская Ангелина Геннадьевна</td>
					<td><a href="mailto:a.syrovatskaya@perseusirk.ru">a.syrovatskaya@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>8</td>
					<td>Отдел развития образовательных программ по направлению «Спорт»</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%A1%D0%BF%D0%BE%D1%80%D1%82.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Стягайло Екатерина Станиславна</td>
					<td><a href="mailto:e.kapustina@perseusirk.ru">e.kapustina@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>9</td>
					<td>Отдел организационно-педагогического сопровождения смен</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE-%D0%BF%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B3%D0%BE-%D1%81%D0%BE%D0%BF%D1%80%D0%BE%D0%B2%D0%BE%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D1%81%D0%BC%D0%B5%D0%BD.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Корецкис Ольга Игоревна</td>
					<td><a href="mailto:o.koretskis@perseusirk.ru">o.koretskis@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>11</td>
					<td>Центр развития конкурсного движения и детских инициатив</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%86%D0%B5%D0%BD%D1%82%D1%80%D0%B5-%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D1%82%D0%B8%D1%8F-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B4%D0%B2%D0%B8%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B8-%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B8%D1%85-%D0%B8%D0%BD%D0%B8%D1%86%D0%B8%D0%B0%D1%82%D0%B8%D0%B2.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Руководитель центра</td>
					<td>Самодурова Вера Геннадьевна</td>
					<td><a href="mailto:v.samodurova@perseusirk.ru">v.samodurova@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>10</td>
					<td>Отдел выявления и сопровождения одаренных детей</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%B2%D1%8B%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B8-%D1%81%D0%BE%D0%BF%D1%80%D0%BE%D0%B2%D0%BE%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BE%D0%B4%D0%B0%D1%80%D0%B5%D0%BD%D0%BD%D1%8B%D1%85-%D0%B4%D0%B5%D1%82%D0%B5%D0%B9.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Сорока Сергей Сергеевич</td>
					<td><a href="mailto:s.soroka@perseusirk.ru">s.soroka@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>12</td>
					<td>Отдел сопровождения олимпиадного и конкурсного движения школьников</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D1%81%D0%BE%D0%BF%D1%80%D0%BE%D0%B2%D0%BE%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B8-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B4%D0%B2%D0%B8%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-%D1%88%D0%BA%D0%BE%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Гавриленко Татьяна Георгиевна</td>
					<td><a href="mailto:t.gavrilenko@perseusirk.ru">t.gavrilenko@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>13</td>
					<td>Отдел проектной деятельности</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BD%D0%BE%D0%B9-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Заведующий отделом</td>
					<td>Шведина Светлана Александровна</td>
					<td><a href="mailto:s.shvedina@perseusirk.ru">s.shvedina@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>14</td>
					<td>Отдел бухгалтерско-экономического
						обеспечения</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5-%D0%B1%D1%83%D1%85%D0%B3%D0%B0%D0%BB%D1%82%D0%B5%D1%80%D1%81%D0%BA%D0%BE-%D1%8D%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
							target="_blank" download>Положение</a></td>
					<td>г. Иркутск, проезд Угольный, д. 68/1</td>
					<td>Главный бухгалтер</td>
					<td>Хисматова Елена Викторовна</td>
					<td><a href="mailto:e.hismatova@perseusirk.ru">e.hismatova@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>15</td>
					<td>Хозяйственно-эксплуатационный отдел</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%A5%D0%BE%D0%B7%D1%8F%D0%B9%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%BE-%D1%8D%D0%BA%D1%81%D0%BF%D0%BB%D1%83%D0%B0%D1%82%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%BC-%D0%BE%D1%82%D0%B4%D0%B5%D0%BB%D0%B5.pdf"
							target="_blank" download>Положение</a></td>
					<td>Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</td>
					<td>Руководитель управления</td>
					<td>Соболева Татьяна Борисовна</td>
					<td><a href="mailto:t.soboleva@perseusirk.ru">t.soboleva@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>16</td>
					<td>Производственно-технический отдел (ПТО)</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%9F%D0%A2%D0%9E.pdf"
							target="_blank" download>Положение</a></td>
					<td>Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</td>
					<td>Заведующий отделом</td>
					<td>Будников Федор Викторович</td>
					<td><a href="mailto:fedor.budnikov.78@mail.ru">fedor.budnikov.78@mail.ru</a></td>
				</tr>
				<tr>
					<td>17</td>
					<td>Столовая</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%A1%D1%82%D0%BE%D0%BB%D0%BE%D0%B2%D0%BE%D0%B9.pdf"
							target="_blank" download>Положение</a></td>
					<td>Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</td>
					<td>Заведующий производством</td>
					<td>Щербаков Владимир Александрович</td>
					<td><a href="mailto:shcherbakov@perseusirk.ru">shcherbakov@perseusirk.ru</a></td>
				</tr>
				<tr>
					<td>18</td>
					<td>Медицинский пункт</td>
					<td><a
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%9C%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%BE%D0%BC-%D0%BF%D1%83%D0%BD%D0%BA%D1%82%D0%B5.pdf"
							target="_blank" download>Положение</a></td>
					<td>Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</td>
					<td>Фельдшер</td>
					<td>Корнилова Мария Михеевна</td>
					<td><a href="mailto:shcherbakov@perseusirk.ru">shcherbakov@perseusirk.ru</a></td>
				</tr>
			</table>
		</div>
	</div>
</template>