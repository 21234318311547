<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>

		<div class="AllRussian-img-box" style="max-width: 500px;">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>

		<h2 class="EventsPage-title">
			«Олимпиадная история»
		</h2>
		<p class="EventsPage-date">
			21 октября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=200" target="_blank">
			Регистрация
		</a>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->

		<p class="JuniorProfi-sub-title">
			Описание курса:
		</p>
		<p class="EventsPage-text">
			Программа курса ориентирована на подготовку обучающихся ко муниципальному этапу всероссийской олимпиады по
			истории.
		</p>

		<p class="JuniorProfi-sub-title">
			Участники:
		</p>
		<p class="EventsPage-text">
			К освоению программы приглашаются в возрасте от 12 до 17 лет.
		</p>
		<p class="EventsPage-text">
			Обучение проходит на бесплатной основе, без вступительных испытаний.
		</p>

		<p class="JuniorProfi-sub-title">
			Программа курса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Раздел 1. Первый тур регионального этапа ВсОШ по истории: специфика заданий и критерии оценивания.
			</li>
			<li class="JuniorProfi-items">
				Раздел 2. Исследовательский проект.
			</li>
			<li class="JuniorProfi-items">
				Раздел 3. Эссе.
			</li>
			<li class="JuniorProfi-items">
				Раздел 4. Историография истории России.
			</li>
			<li class="JuniorProfi-items">
				Раздел 5. История развития русской живописи.
			</li>
			<li class="JuniorProfi-items">
				Раздел 6. Исторический источник.
			</li>
			<li class="JuniorProfi-items">
				Итоговое тестирование.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Занданова Лариса Викторовна, профессор кафедры истории и методики Педагогического института ФГБОУ ВО «Иркутский
				государственный университет».
			</li>
			<li class="JuniorProfi-items">
				Кулакова Яна Викторовна, кандидат исторических наук кафедры истории и методики Педагогического института ФГБОУ
				ВО «Иркутский государственный университет».
			</li>
			<li class="JuniorProfi-items">
				Салахова Лариса Марсовна, кандидат исторических наук кафедры истории и методики Педагогического института ФГБОУ
				ВО «Иркутский государственный университет».
			</li>
			<li class="JuniorProfi-items">
				Серебренников Игорь Петрович, кандидат исторических наук кафедры истории и методики Педагогического института
				ФГБОУ ВО «Иркутский государственный университет».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 21 октября 2024 года на платформе Навигатор дополнительного образования детей Иркутской
				области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 21 октября 2024 года на платформе Moodle, Образовательного центра
				«Персей» <a style="color:blue" href="https://moodle.perseusirk.ru/course/view.php?id=200">ссылка на
					регистрацию</a>
			</li>
			<li class="JuniorProfi-items">
				<a style="color:blue" href="https://р38.навигатор.дети/program/26938-programma-olimpiadnaya-istoriya">
					Ссылка на подачу заявки в	Навигатор
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%BF%D0%BE-%D0%B4%D0%B8%D1%81%D1%82.-%D0%BA%D1%83%D1%80%D1%81%D0%B0%D0%BC-%D0%92%D0%A1%D0%9E%D0%A8.pdf"
					target="_blank">
					Письмо по дист. курсам ВСОШ
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Гавриленко Татьяна Георгиевна
			</li>
			<li class="JuniorProfi-items">
				Непомнящая Екатерина Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Бутакова Светлана Владимировна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

	</div>
</template>