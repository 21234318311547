<template>
	<div class="SchoolStage _cover">
		<h2 class="SchoolStage-title _cover-title">«Школьный этап» 2024-2025г.</h2>
		<div class="AllRussian-img-box" style="max-width: 500px;">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>

		<div class="AllRussian-btn-box">
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'VsoshPage' })">
				Всероссийская олимпиада школьников
			</a>
		</div>

		<p class="JuniorProfi-sub-title">
			Уважаемые участники!
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">График школьного этапа индивидуальный для каждого муниципального образования
				Иркутской области. О графике туров школьного этапа вы можете узнать в вашей школе или у Вашего муниципального
				координатора.</li>
			<li class="vsosh2024-items">Большинство предметов школьного этапа проходят в традиционной форме, по предметам
				математика, информатика, физика, химия, биология, астрономия - на сайте Сириус.Курсы.</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Графики школьного этапа Олимпиады в муниципальных образованиях Иркутской области
		</p>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/5EB9pMpGhQKW9w" target="_blank">
					Аларский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/eA2LguuA0NKxiA" target="_blank">
					Баяндаевский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/qm0Y2vcKTC0HLw" target="_blank">
					Боханский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/HVWzKaroS9FJFQ" target="_blank">
					Братский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/f2vBStIGn8FyRw" target="_blank">
					город Ангарск
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/gJHpLKtOsZsrKA" target="_blank">
					город Бодайбо и район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/NLc_wJfTSP2cHQ" target="_blank">
					город Братск
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/82-8nVY4_tZ80A" target="_blank">
					город Зима
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/5fBILu6VnPo2zw" target="_blank">
					город Иркутск
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/r0qYYCcyhnYOIw" target="_blank">
					город Саянск
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/JdxIzIandt50lg" target="_blank">
					город Свирск
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/GD5zXVzJcB7f_g" target="_blank">
					город Тулун
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/PY8AJfxTq37I9A" target="_blank">
					город Усолье-Сибирское
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/021SObhWxuvhSg" target="_blank">
					город Усть-Илимск
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/j_B7JepkKS0KnA" target="_blank">
					город Черемхово
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/dMLmgbrBwIbF3g" target="_blank">
					Жигаловский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/_7H0cDpJl141kQ" target="_blank">
					Заларинский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/AUOwyfuKIP7Z-w" target="_blank">
					Зиминский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/puoRf20jWSv-0w" target="_blank">
					Иркутский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/jTdYvLAMT5hOxQ" target="_blank">
					Казачинско-Ленский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/-bYwzcWGJ7YN7w" target="_blank">
					Качугский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/833R0RGM7jysNQ" target="_blank">
					Киренский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/bOzCEXZSlH5ZJA" target="_blank">
					Куйтунский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/zxm1yNqEEJk3Yw" target="_blank">
					Нижнеилимский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/8uVrnTePcyihQQ" target="_blank">
					Нижнеудинский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/Ms8mS2r-VehZ7g" target="_blank">
					Нукутский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/H0g1jJwBDajLTA" target="_blank">
					Ольхонский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/jjZ5E3P-IC03YA" target="_blank">
					Тайшетский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/6V6RyBHJh-abvA" target="_blank">
					Тулунский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/YQyeiM_bYkC_3Q" target="_blank">
					Усольский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/oBD_cM2iQbGI7A" target="_blank">
					Усть-Илимский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/8fuHjBy47UItLA" target="_blank">
					Усть-Кутский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/zfbiS05uWOEfTQ" target="_blank">
					Черемховский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/zAfxjBVEXp-RUg" target="_blank">
					Шелеховский район
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/i/DRPekMpvxqIkew" target="_blank">
					Эхирит-Булагатский район
				</a>
			</li>
		</ul>

		<div class="AllRussian-img-box" style="max-width: 750px;">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ШЭВсОШ.webp"
				alt="Всероссийская олимпиада школьников">
		</div>

		<p class="JuniorProfi-sub-title">
			Памятка для участника школьного этапа всероссийской олимпиады школьников на платформе «Сириус.Курсы»
		</p>
		<p class="vsosh2024-text">
			Если вы собираетесь участвовать в олимпиаде по одному или нескольким предметам: математика, информатика, физика,
			химия, биология, астрономия, то эта инструкция — для вас.
		</p>

		<p class="JuniorProfi-sub-title">
			КАЛЕНДАРЬ
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">
				Внимательно ознакомьтесь с датами туров, актуальными для вашего региона. Распределение регионов по группам: <a
					style="color:blue" href="siriusolymp.ru/about#groups">siriusolymp.ru/about#groups</a>.
			</li>
			<li class="vsosh2024-items">
				Расписание туров: <a style="color:blue" href="siriusolymp.ru/about#schedule">siriusolymp.ru/about#schedule</a>.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			КОД УЧАСТНИКА
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">Сообщите своему учителю в школе о желании участвовать в олимпиаде. Учитель
				подскажет, есть ли в школе установленный график проведения олимпиады, или вы можете принять в ней участие в
				любое удобное для вас время с 8:00 до 22:00 в день проведения тура.</li>
			<li class="vsosh2024-items">Получите у своего учителя, классного руководителя или школьного координатора
				индивидуальные коды для участия и сохраните их.</li>
			<li class="vsosh2024-items">Для каждого предмета необходимо получить свой код участника.</li>
			<li class="vsosh2024-items">Код можно активировать на платформе «Сириус.Курсы» <a style="color:blue"
					href="uts.sirius.online">uts.sirius.online</a> не ранее чем за
				1 час до начала тура.</li>
			<li class="vsosh2024-items">После получения кода участника не меняйтесь ими с друзьями, иначе ваши результаты
				будет невозможно идентифицировать.</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			ЗАДАНИЯ
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">Узнайте на сайте олимпиады <a style="color:blue"
					href="siriusolymp.ru">siriusolymp.ru</a>, сколько времени отводится на решение
				заданий олимпиады по выбранному предмету.</li>
			<li class="vsosh2024-items">Обратите внимание на то, что время для решения олимпиады зависит как от класса, так
				и от предмета.</li>
			<li class="vsosh2024-items">Вы имеете право писать олимпиаду за класс старше, но не наоборот.</li>
			<li class="vsosh2024-items">Для решения некоторых туров вам могут понадобятся дополнительные материалы. </li>
		</ul>
		<p class="vsosh2024-text">
			Ознакомиться с ними можно на предметных страницах, там же где будут расположены кнопки для входа в олимпиаду.
			Сделайте это заранее, чтобы подготовить все необходимое.
		</p>

		<p class="JuniorProfi-sub-title">
			РАБОЧЕЕ МЕСТО ОЛИМПИАДНИКА
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">
				Если вы пишете олимпиаду вне школы, то позаботьтесь о том, чтобы во время участия у вас было заряженное
				устройство с устойчивым Интернет-соединением. Не забудьте взять ручку и черновик, а также дополнительные
				материалы, если они необходимы.
			</li>
			<li class="vsosh2024-items">
				Если у вас нет компьютера или ноутбука, то вы можете выполнять задания по математике, информатике (5-6
				классы), физике, химии, биологии и астрономии с телефона, так как тестирующая система полностью адаптирована
				для
				мобильных устройств и планшетов. Для участия в туре по информатике для 7-11 классов вам понадобится компьютер
				или ноутбук с установленным языком программирования. Подробнее о требованиях по информатике смотрите на
				странице
				предмета.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			ПРАВИЛА РАБОТЫ В СИСТЕМЕ
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">
				После того как вы введете свой код участника, прочитаете приветственный текст и нажмете кнопку «Начать»,
				стартует отсчет времени.
			</li>
			<li class="vsosh2024-items">
				Остановить время нельзя, отсчёт продолжится, даже если вы выйдете из системы, выключите компьютер или у вас
				пропадет интернет.
			</li>
			<li class="vsosh2024-items">
				Каждый ответ к задаче необходимо сохранить. Кнопка «Сохранить» находится внизу страницы справа. Сохранять
				каждый ответ можно несколько раз. На проверку будут переданы только сохраненные ответы. В туре по информатике
				в
				задачах по программированию учитывается лучшее решение.
			</li>
			<li class="vsosh2024-items">
				Олимпиада закончится по истечении отведенного времени или в 22:00 по местному времени. Несданные до 22:00
				работы будут автоматически приняты и отправлены на проверку.
			</li>
		</ul>

		<p class="vsosh2024-text">
			Если на решение задач отводится час, то лучше приступить к выполнению не позднее 21:00.
		</p>

		<p class="JuniorProfi-sub-title">
			ПОСЛЕ ОЛИМПИАДЫ
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">
				В течение двух дней после дня проведения тура на сайте олимпиады siriusolymp.ru будут опубликованы текстовые
				разборы и видеоразборы заданий.
			</li>
			<li class="vsosh2024-items">
				Предварительные результаты вы сможете узнать по своему коду участника через 7 дней после дня проведения тура.
				Окончательные результаты будут доступны в вашей школе не ранее, чем через 14 дней после дня проведения тура.
			</li>
			<li class="vsosh2024-items">
				Разберите вместе с учителем вашу работу, используя текстовые и видеоразборы. Если после этого у вас остались
				вопросы по выставленным вам баллам, учитель сможет отправить их региональному координатору.
			</li>
		</ul>

		<h2 class="SchoolStage-title _cover-title">Успехов!</h2>

	</div>
</template>


