<template>
	<div class="ContactsofOlympiad _cover">
		<h2 class="ContactsofOlympiad-title _cover-title">Контакты координаторов олимпиады в Иркутской области</h2>
		<div class="AllRussian-btn-box">
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'VsoshPage' })">
				Всероссийская олимпиада школьников
			</a>
		</div>
		<h3 class="ContactsofOlympiad-sub-title">Куратор Всероссийской олимпиады школьников в Иркутской области:</h3>
		<p class="ContactsofOlympiad-dist">Большакова Инга Михайловна, ведущий советник отдела общего и дошкольного
			образования управления общего и дополнительного образования министерства образования Иркутской области, тел. <a
				style="color: blue" href="tel:+73952201638">8 (3952) 20-16-38</a></p>
		<h3 class="ContactsofOlympiad-sub-title">Ответственные лица оператора Всероссийской олимпиады школьников в Иркутской
			области:</h3>
		<p class="ContactsofOlympiad-dist">
			Гавриленко Татьяна Георгиевна<br />
			Непомнящая Екатерина Сергеевна<br />
			тел. <a style="color: blue" href="tel:+73952546044">8 (3952) 54-60-44 </a>
		</p>

		<h2 class="ContactsofOlympiad-title _cover-title">Контакты координаторов муниципальных образований Иркутской области
		</h2>
		<div class="StructureAndOrgans-table">
			<table width="100%">
				<tr>
					<td>Муниципалитет</td>
					<td>Координатор</td>
					<td>Телефон</td>
					<td>Ссылка на страницу с информацией Олимпиады</td>
				</tr>
				<tr>
					<td>Аларский район</td>
					<td>Исхакова Жанна Владимировна</td>
					<td>+7 395 643-71-28</td>
					<td>
						<a href="http://kpo.alaredu.ru/obrazovanie/kachestvo-obrazovaniya/olimpiada/всош-2023-2024.html">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>Балаганский район</td>
					<td>Слободчикова Елена Сергеевна</td>
					<td>89526102043</td>
					<td></td>
				</tr>
				<tr>
					<td>Баяндаевский район</td>
					<td>Ихинова Галина Викторовна</td>
					<td>+7 904 159-48-18</td>
					<td><a href="https://obrazovanie-bnd.ucoz.net/index/vserossijskaja_olimpiada_shkolnikov/0-12">Ссылка</a></td>
				</tr>
				<tr>
					<td>Боханский район</td>
					<td>Афанасьева Наталья Леонидовна</td>
					<td>+7 395 382-54-72</td>
					<td><a href="https://бохан-обр.рф/всероссийская-олимпиада-школьников/">Ссылка</a></td>
				</tr>
				<tr>
					<td>Братский район</td>
					<td>Большешапова Светлана Сергеевна</td>
					<td>+7 395 321-06-50</td>
					<td><a href="https://clck.ru/3DAgxe">Ссылка</a></td>
				</tr>
				<tr>
					<td>г. Бодайбо и район</td>
					<td>Малышевская Ирина Николаевна</td>
					<td>+7 395 615-23-10</td>
					<td><a href="http://rcobdb.uobodaibo.ru/index/vserossijskaja_olimpiada_shkolnikov/0-1">Ссылка</a></td>
				</tr>
				<tr>
					<td>город Ангарск</td>
					<td>Солодаева Юлия Евгеньевна</td>
					<td>+7 395 559-19-17</td>
					<td><a href="http://edu-angarsk.ru/index/dejatelnost/0-142">Ссылка</a></td>
				</tr>
				<tr>
					<td>город Братск</td>
					<td>Обухова Татьяна Викторовна</td>
					<td>+7 395 334-95-21</td>
					<td><a href="https://obrbratsk.ru/do/ooo/olymp/">Ссылка</a></td>
				</tr>
				<tr>
					<td>город Зима</td>
					<td>Антонюк Александра Олеговна</td>
					<td>+7 395 543-63-34</td>
					<td><a href="http://www.uozima.ru/working-with-gifted-children/olympics">Ссылка</a></td>
				</tr>
				<tr>
					<td>город Иркутск</td>
					<td>Мальчукова Анна Геннадьевна - по орг. вопросам<br>Ефремова Светлана Александровна - по вопросам АИС</td>
					<td>+7 395 234-37-05</td>
					<td>
						<a
							href="https://mc.eduirk.ru/index.php?option=com_k2&amp;view=itemlist&amp;task=category&amp;id=1248:vserossijskaya-olimpiada-shkolnikov-vsosh&amp;Itemid=144;">Ссылка</a><br>
						<a
							href="https://mc.eduirk.ru/index.php?option=com_k2&amp;view=itemlist&amp;task=category&amp;id=1452:she-vsosh-2024-2025&amp;Itemid=144">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>город Саянск</td>
					<td>Михалева Наталья Викторовна</td>
					<td>+7 395 535-66-92</td>
					<td>
						<a
							href="https://uo-sayansk.ru/napravleniya-deyatelnosti/rabota-s-odarennymi-detmi/vserossijskaya-olimpiada-shkolnikov.html">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>город Свирск</td>
					<td>Черниговская Татьяна Анатольевна</td>
					<td>+7 395 732-21-36</td>
					<td></td>
				</tr>
				<tr>
					<td>город Тулун</td>
					<td>Лыткина Анастасия Геннадьевна</td>
					<td>+7 395 304-05-02</td>
					<td><a href="https://mbumc.3dn.ru/index/vserossijskaja_olimpiada_shkolnikov/0-63">Ссылка</a></td>
				</tr>
				<tr>
					<td>город Усолье-Сибирское</td>
					<td>Сучкова Елизавета Сергеевна</td>
					<td>89016592166</td>
					<td><a href="http://mu-imc.ru/index.php/rabota-s-odarennymi-detmi/predmetnye-olimpiady-shkolnikov">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>город Усть-Илимск</td>
					<td>Козловская Анастасия Владимировна</td>
					<td>+7 395 356-54-04</td>
					<td><a href="https://uiedu.ru/одаренные-дети/развитие-способностей-и-талантов/">Ссылка</a></td>
				</tr>
				<tr>
					<td>город Черемхово</td>
					<td>Черных Людмила Александровна</td>
					<td>+7 395 465-14-64</td>
					<td>
						<a
							href="https://cherobr.ru/index.php/otdel-informatsionno-metodicheskogo-soprovozhdeniya/615-vserossijskaya-olimpiada-shkolnikov">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>Жигаловский район</td>
					<td>Зелинская Тамара Петровна</td>
					<td>+7 395 513-16-07</td>
					<td><a href="https://zhiguo.ucoz.ru/index/vserossijskaja_olimpiada_shkolnikov/0-106">Ссылка</a></td>
				</tr>
				<tr>
					<td>Заларинский район</td>
					<td>Архипов Александр Иннокентьевич</td>
					<td>+7 395 522-11-40</td>
					<td><a href="https://komobrzal.ucoz.ru/index/osnovnoe_srednee_obshhee_obrazovanie/0-4">Ссылка</a></td>
				</tr>
				<tr>
					<td>Зиминский район</td>
					<td>Тарханова Юлия Ивановна</td>
					<td>+7 395 543-17-58</td>
					<td><a href="http://komitetzrmo.ru/index20cro.html">Ссылка</a></td>
				</tr>
				<tr>
					<td>Иркутский район</td>
					<td>Конщина Наталья Александровна</td>
					<td>+7 395 271-80-34</td>
					<td><a href="http://eduraion.irk.ru/p/VserosOlimpiada">Ссылка</a></td>
				</tr>
				<tr>
					<td>Казачинско-Ленский район</td>
					<td>Лисичникова Евгения Валерьевна</td>
					<td>+7 395 623-29-85</td>
					<td><a href="http://rc-kazachinsk.ru/index/shkolnyj_ehtap_2024_25/0-329">Ссылка</a></td>
				</tr>
				<tr>
					<td>Катангский район</td>
					<td>Щеглова Татьяна Геннадьевна</td>
					<td>+7 395 602-17-70</td>
					<td></td>
				</tr>
				<tr>
					<td>Качугский район</td>
					<td>Гусева Ксения Николаевна</td>
					<td>+7 395 403-14-09</td>
					<td><a href="http://www.rono.edkachug.ru/index.php/vserossijskaya-olimpiada-shkolnikov">Ссылка</a></td>
				</tr>
				<tr>
					<td>Киренский район</td>
					<td>Полоскова Татьяна Анатольевна</td>
					<td>+7 395 684-35-94</td>
					<td><a href="https://цро-киренск.рф/odarennye-deti/">Ссылка</a></td>
				</tr>
				<tr>
					<td>Куйтунский район</td>
					<td>Дятлова Наталья Борисовна</td>
					<td>+7 395 365-14-64</td>
					<td><a href="https://uo.edu-kuitun.ru/activity/education/child/VSOSH/">Ссылка</a></td>
				</tr>
				<tr>
					<td>Мамско-Чуйский район</td>
					<td>Пархомчук Ирина Петровна</td>
					<td>+7 395 692-16-51</td>
					<td><a href="https://edu-mchr.irkmo.ru/vserossiyskaya-olimpiada-shkolnikov-/">Ссылка</a></td>
				</tr>
				<tr>
					<td>Нижнеилимский район</td>
					<td>Попович Марина Геннадьевна</td>
					<td>+7 395 663-25-37</td>
					<td><a href="https://do.nilimsk.ru/index.php/school-olymp">Ссылка</a></td>
				</tr>
				<tr>
					<td>Нижнеудинский район</td>
					<td>Старовойтова оксана Викторовна</td>
					<td>+7 395 577-11-65</td>
					<td><a href="https://nruo.ru/vosh.html">Ссылка</a></td>
				</tr>
				<tr>
					<td>Нукутский район</td>
					<td>Булсунаева Любовь Андреевна</td>
					<td>+7 395 492-18-04</td>
					<td><a href="https://цонр.рф/sveden/vserossiyskaya-olimpiada/">Ссылка</a></td>
				</tr>
				<tr>
					<td>Ольхонский район</td>
					<td>Загулова Валентина Викторовна</td>
					<td>+7 395 243-69-46</td>
					<td><a href="http://xn----xtbebbkr.xn–p1ai/vserossiyskaya-olimpiada-shkol-nikov.html">Ссылка</a></td>
				</tr>
				<tr>
					<td>Осинский район</td>
					<td>Васильева Екатерина Владимировна</td>
					<td>+7 395 393-13-55</td>
					<td></td>
				</tr>
				<tr>
					<td>Слюдянский район</td>
					<td>МКУ &quot;МЦБ&quot; Слюдянского района</td>
					<td>+7 395 445-22-96</td>
					<td></td>
				</tr>
				<tr>
					<td>Тайшетский район</td>
					<td>Андреева Арина Витальевна</td>
					<td>+7 839 522-40-33</td>
					<td><a href="https://uo-taishet.ru/vserossijskaya-olimpiada-shkolnikov/shkolnyj-etap-vsosh">Ссылка</a></td>
				</tr>
				<tr>
					<td>Тулунский район</td>
					<td>Шипачева Аурика Николаевна</td>
					<td>+7 395 304-01-20</td>
					<td><a href="http://tulunrono.tulunr.ru/index.php/olimpiada">Ссылка</a></td>
				</tr>
				<tr>
					<td>Усольский район</td>
					<td>Андреева Наталья Борисовна</td>
					<td>+7 395 433-60-74</td>
					<td><a href="http://uoura.ru/index.php/obshchee-obrazovanie2019/2018-09-28-03-09-57">Ссылка</a></td>
				</tr>
				<tr>
					<td>Усть-Илимский район</td>
					<td>Янченко Галина Максимовна</td>
					<td>+7 395 357-71-52</td>
					<td></td>
				</tr>
				<tr>
					<td>Усть-Кутский район</td>
					<td>Мамуркова Таисья Федоровна</td>
					<td>+7 395 655-21-26</td>
					<td><a href="https://ustkut.uoedu.ru/site/section?id=29">Ссылка</a></td>
				</tr>
				<tr>
					<td>Усть-Удинский район</td>
					<td>Дедик Ирина Николаевна</td>
					<td>+7 395 453-14-20</td>
					<td><a href="https://ustkut.uoedu.ru/site/section?id=29">Ссылка</a></td>
				</tr>
				<tr>
					<td>Черемховский район</td>
					<td>Грибанова Татьяна Дмитриевна</td>
					<td>+7 395 465-53-03</td>
					<td><a
							href="https://oo.cheredu.ru/index.php/tsentr-razvitiya-obrazovaniya/rabota-s-odarennymi-detmi/olimpiady">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>Чунский район</td>
					<td>Шалак Анна Николаевна</td>
					<td>+7 395 672-17-87</td>
					<td><a
							href="http://chuna-rono.ru/index.php/otdel-obrazovaniya/deyatelnost/65-vserossijskaya-olimpiada-shkolnikov">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>Шелеховский район</td>
					<td>Калашник Татьяна Анатольевна</td>
					<td>+7 395 505-37-94</td>
					<td></td>
				</tr>
				<tr>
					<td>Шелеховский район</td>
					<td>Васильева Елена Ивановна</td>
					<td>+7 395 504-99-34</td>
					<td>
						<a
							href="https://imoc.sheledu.ru/index.php/for-students/vserossijskaya-olimpiada-shkolnikov/etapy-vserossijskoj-olimpiady-shkolnikov-2024-2025">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>Эхирит-Булагатский район</td>
					<td>Абашеева Лира Николаевна</td>
					<td>+7 395 413-11-05</td>
					<td>
						<a
							href="https://uo.ehirit38.ru/index.php?option=com_content&amp;view=article&amp;id=825:olimpiada-2024-2025&amp;catid=44&amp;Itemid=227">Ссылка</a>
					</td>
				</tr>
			</table>
		</div>
	</div>
</template>
