<template>
	<div class="BasicInformation _cover">
		<h2 class="BasicInformation _cover-title">
			Основные сведения
		</h2>

		<ul class="BasicInformation-list">
			<li class="BasicInformation-items">
				Полное наименование образовательной организации:
				<p class="BasicInformation-text">
					Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный
					центр выявления и поддержки одаренных детей «Персей»
				</p>
			</li>
			<li class="BasicInformation-items">
				Сокращенное наименование образовательной организации:
				<p class="BasicInformation-text">
					Образовательный центр «Персей»
				</p>
			</li>
			<li class="BasicInformation-items">
				Дата создания образовательной организации:
				<p class="BasicInformation-text">
					20 декабря 2022 года (Распоряжение Правительства Иркутской области от 15 ноября 2022 года № 633-рп «О создании
					Государственного автономного нетипового учреждения дополнительного образования Иркутской области «Региональный
					центр выявления и поддержки одаренных детей «Персей»)
				</p>
			</li>
			<li class="BasicInformation-items">
				Учредитель образовательной организации:
				<p class="BasicInformation-text">
					Министерство образования Иркутской области<br>
					Адрес: 664027, Иркутская область, г. Иркутск, Российская улица, 21<br>
					Должность руководителя: Министр образования Иркутской области<br>
					ФИО руководителя: Парфенов Максим Александрович<br>
					Телефон: 8(3952) 33-13-33 (приемная)<br>
					E-mail: obraz@38edu.ru<br>
					Адрес сайта: <a href="https://minobr.irkobl.ru/">https://minobr.irkobl.ru/</a><br>
					ИНН: 3808172408<br>
				</p>
			</li>
			<li class="BasicInformation-items">
				Представительства и филиалы образовательной организации:
				<p class="BasicInformation-text">
					Отсутствуют
				</p>
			</li>
			<li class="BasicInformation-items">
				Место нахождения образовательной организации:
				<p class="BasicInformation-text">
					Корпус №1 (юридический адрес)<br>
					664047, Иркутская область, г. Иркутск, Угольный проезд, д. 68/1
				</p><br>
				<p class="BasicInformation-text">
					Корпус №2<br>
					664035, Иркутская область, г. Иркутск, ул. Рабочего Штаба, д. 15
				</p><br>
				<p class="BasicInformation-text">
					Корпус №3<br>
					665853, Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны
				</p>
			</li>
			<li class="BasicInformation-items">
				Режим и график работы образовательной организации:
				<p class="BasicInformation-text">
					понедельник — пятница: 8:00 – 17:00, перерыв на обед: 12:00 – 13:00<br>
					суббота — воскресенье: выходной день
				</p>
			</li>
			<li class="BasicInformation-items">
				Контактные телефоны образовательной организации:
				<p class="BasicInformation-text">
					Корпус №1 (г. Иркутск, Угольный проезд, 68/1)<br>
					8 (3952) 54-60-44
				</p>
				<p class="BasicInformation-text">
					Корпус №2 (г. Иркутск, ул. Рабочего Штаба, д. 15)<br>
					8 (3952) 54-60-44
				</p>
				<p class="BasicInformation-text">
					Корпус №3 (кампус Образовательного центра «Персей»)<br>
					8(902) 514-76-67
				</p>
			</li>
			<li class="BasicInformation-items">
				Адрес электронной почты:
				<a href="mailto:perseus@perseusirk.ru" class="BasicInformation-text">
					perseus@perseusirk.ru
				</a>
			</li>
			<li class="BasicInformation-items">
				Адрес официального сайта образовательной организации в информационно-телекоммуникационной сети «Интернет»:
				<p class="BasicInformation-text">
					Официальный сайт Образовательного центра «Персей»: <a href="http://perseusirk.ru/">http://perseusirk.ru/</a>
				</p>
			</li>
			<li class="BasicInformation-items">
				Места осуществления образовательной деятельности:
				<p class="BasicInformation-text">
					Иркутская область, г. Иркутск, Угольный проезд, д. 68/1
				</p>
				<p class="BasicInformation-text">
					Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны
				</p>
				<p class="BasicInformation-text">
					Иркутская область, г. Иркутск, ул. Рабочего Штаба, д. 15
				</p>
			</li>
			<li class="BasicInformation-items">
				Лицензия на осуществление образовательной деятельности:
				<ul class="Documents-list">
					<li class="Documents-list-items">
						<a class="Documents-list-items-link"
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A3%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%B2%D1%8B%D0%B4%D0%B0%D1%87%D0%B5-%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B8.pdf"
							target="_blank">
							Уведомление о выдаче лицензии
						</a>
					</li>
					<li class="Documents-list-items">
						<a class="Documents-list-items-link"
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%A3%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D1%80%D0%B5%D0%B5%D1%81%D1%82%D1%80-%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B9.pdf"
							target="_blank">
							Уведомление о внесении изменений в реестр лицензий
						</a>
					</li>
					<li class="Documents-list-items">
						<a class="Documents-list-items-link"
							href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%92%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B0-%D0%B8%D0%B7-%D1%80%D0%B5%D0%B5%D1%81%D1%82%D1%80%D0%B0-%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B9-%E2%84%96-%D0%9B035-01220-38-00653029-%D0%BE%D1%82-07.08.2024.pdf"
							target="_blank">
							Выписка-из-реестра-лицензий-№-Л035-01220-38-00653029-от-07.08.2024
						</a>
					</li>
				</ul>
			</li>
			<li class="BasicInformation-items">
				Государственная аккредитация образовательной деятельности по реализуемым образовательным программам:
				<p class="BasicInformation-text">
					Не предусмотрена действующим законодательством
				</p>
			</li>
		</ul>

	</div>
</template>

<style>
.BasicInformation-list {}

.BasicInformation-items {
	font-weight: 700;
	margin-bottom: 20px;
}

.BasicInformation-text {
	margin: 5px 0;
	font-weight: 500;
}
</style>