<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Скрипичное ансамблевое исполнительство»
		</h2>
		<p class="EventsPage-date">
			с 18 по 31 октября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=192" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25662-programma-skripichnoe-ansamblevoe-ispolnitelstvo" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» проводит профильную смену
			«Скрипичное ансамблевое исполнительство» для обучающихся организаций дополнительного образования по направлению
			инструментальное исполнительство от 10 до 17 лет, заявившие в добровольном порядке свое намерение участвовать в
			мероприятиях смены и проявивших себя в творческой деятельности в области скрипичного инструментального
			исполнительства, а также, обладающими основными музыкальными способностями: слух, ритм, память, исполнительские
			навыки игры на скрипке, а также начальными навыками игры в ансамбле.
		</p>

		<p class="JuniorProfi-sub-title">
			Обучение по программе предполагает наличие определенных умений:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				исполнительских навыков игры на скрипке;
			</li>
			<li class="JuniorProfi-items">
				начальных навыков игры в ансамбле.
			</li>
			<li class="JuniorProfi-items">
				исполнять музыкальные произведения на достаточном художественном уровне в соответствии с их стилевыми
				особенностями;
			</li>
			<li class="JuniorProfi-items">
				слушать и слышать всю партитуру оркестра;
			</li>
			<li class="JuniorProfi-items">
				быстро переходить из одного эмоционального состояния в другое при смене жанра произведений;
			</li>
			<li class="JuniorProfi-items">
				тонко понимать интонацию: мотив, фразу в строении музыкальной формы;
			</li>
			<li class="JuniorProfi-items">
				контролировать синхронность совместной игры;
			</li>
			<li class="JuniorProfi-items">
				умение понимать жест дирижёра.
			</li>
		</ul>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Харченко Наталья Григорьевна, директор МБУДО «Детская школа искусств № 1» Ангарского городского округа,
				Заслуженный работник культуры РФ, преподаватель по классу скрипки высшей квалификационной категории.
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
			Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
		</p>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету участника на платформе электронной информационно-образовательной среды
				Образовательного центра «Персей» по ссылке: https://moodle.perseusirk.ru/course/view.php?id=192, где необходимо
				прикрепить до 6 октября 2024 года включительно:
			</li>
			<li class="JuniorProfi-items">
				Портфолио с дипломами, грамотами за участие в конкурсах регионального и всероссийского уровня в номинации
				«Инструментальное исполнительство» за 2023 и 2024 год;
			</li>
			<li class="JuniorProfi-items">
				Мотивационное письмо на тему «Музыка и скрипка в моей жизни», в котором написать о себе, о наиболее значимых
				мероприятиях, в которых вы принимали участие как исполнитель в сольной программе или в составе ансамбля (не
				более 1000 символов).
			</li>
			<li class="JuniorProfi-items">
				Записаться в ресурсе «Навигатор дополнительного образования Иркутской области» по ссылке:
				https://р38.навигатор.дети/program/25662-programma-skripichnoe-ansamblevoe-ispolnitelstvo
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Басова Тамара Васильевна</li>
			<li class="EventsPage-number">Булыгина Ева Романовна</li>
			<li class="EventsPage-number">Верхозина Влада Викторовна</li>
			<li class="EventsPage-number">Гончарова Анна Евгеньевна</li>
			<li class="EventsPage-number">Горбачёва Анастасия Олеговна 2 аккаунта</li>
			<li class="EventsPage-number">Гранина Мария Юрьевна</li>
			<li class="EventsPage-number">Закревская Аделина Денисовна</li>
			<li class="EventsPage-number">Зверева Дарья Михайловна</li>
			<li class="EventsPage-number">Иванов Глеб Владимирович</li>
			<li class="EventsPage-number">Калмынина Виктория Максимовна</li>
			<li class="EventsPage-number">Копытко Екатерина Михайловна</li>
			<li class="EventsPage-number">Морозова Агния Дмитриевна</li>
			<li class="EventsPage-number">Надточая Софья Олеговна</li>
			<li class="EventsPage-number">Нечецкая Валерия Константиновна</li>
			<li class="EventsPage-number">Опарин Виталий Алексеевич</li>
			<li class="EventsPage-number">Панчукова Арина Дмитриевна</li>
			<li class="EventsPage-number">Росликов Егор Евгеньевич 2 аккаунта</li>
			<li class="EventsPage-number">Святская Екатерина Александровна</li>
			<li class="EventsPage-number">Сербина Анастасия Евгеньевна</li>
			<li class="EventsPage-number">Турчанинова Мария Олеговна</li>
			<li class="EventsPage-number">Цоллер Анастасия Алексеевна</li>
			<li class="EventsPage-number">Чертовских Василий Юрьевич</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>