<template>
	<div class="VsoshPage _cover">
		<h2 class="VsoshPage-title _cover-title">
			Всероссийская олимпиада школьников
		</h2>

		<div class="AllRussian-img-box" style="max-width: 500px;">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>

		<div class="AllRussian-btn-box">
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'VsoshDocuments' })">
				Документы
			</a>
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'SchoolStage' })">
				Школьный этап
			</a>
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'Preparation' })">
				Подготовка к олимпиаде
			</a>
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'ContactsofOlympiad' })">
				Контакты
			</a>
		</div>

		<p class="JuniorProfi-sub-title">
			Предметы участия:
		</p>
		<p class="vsosh2024-text">
			Математика, русский язык, иностранный язык (английский, немецкий, французский, испанский, китайский, итальянский),
			информатика, физика, химия, биология, экология, география, астрономия, литература, история, обществознание,
			экономика, право, искусство (мировая художественная культура), физическая культура, технология, основы
			безопасности жизнедеятельности и защиты Родины.
		</p>

		<p class="JuniorProfi-sub-title">
			График олимпиады:
		</p>
		<p class="vsosh2024-text">
			Школьный этап: сентябрь-октябрь.
		</p>
		<p class="vsosh2024-text">
			Муниципальный этап: ноябрь-декабрь.
		</p>
		<p class="vsosh2024-text">
			Региональный этап: январь-февраль.
		</p>
		<p class="vsosh2024-text">
			Заключительный этап: март-апрель.
		</p>

		<p class="JuniorProfi-sub-title">
			Условия участия:
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">К участию в школьном этапе приглашаются все желающие обучающиеся.</li>
			<li class="vsosh2024-items">Обучающийся может участвовать в любом количестве предметов.</li>
			<li class="vsosh2024-items">Обучающийся выбирает участие за свой класс обучения или за любой из классов выше.
			</li>
			<li class="vsosh2024-items">Если обучающийся выбирает участие за класс выше обучения, он сохраняет выбранный
				класс на протяжении всех этапов олимпиады.</li>
			<li class="vsosh2024-items">Если обучающийся осваивает программу обучения в форме самообразования или семейного
				образования, на момент проведения олимпиады он должен быть прикреплён к образовательной организации, в которую
				он зачислен для прохождения государственной итоговой аттестации.</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Регистрация на олимпиаду:
		</p>
		<p class="vsosh2024-text">
			Для участия в школьном этапе участник должен зарегистрироваться и подать заявку на сайте <a style="color:blue"
				href="https://eor.vserosperseusirk.ru/">https://eor.vserosperseusirk.ru/</a>. Дополнительно необходимо сообщить
			о заявке на участие школьному координатору олимпиады.
		</p>

		<p class="JuniorProfi-sub-title">
			Приглашение на последующие этапы участия:
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">На муниципальный этап приглашаются участники школьного этапа текущего учебного года,
				набравшие необходимые для участия баллы, установленные министерством образования Иркутской области, а также
				победители и призеры муниципального этапа олимпиады предыдущего учебного года.</li>
			<li class="vsosh2024-items">На региональный этап приглашаются участники муниципального этапа олимпиады текущего
				учебного года, набравшие необходимые для участия баллы, установленные министерством образования Иркутской
				области, а также победители и призеры регионального этапа олимпиады предыдущего учебного года.</li>
			<li class="vsosh2024-items">На заключительный этап приглашаются участники регионального этапа олимпиады текущего
				учебного года, набравшие необходимые для участия баллы, установленные Министерством Просвещения Российской
				Федерации, а также победители и призеры заключительного олимпиады предыдущего учебного года.</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Классы участия:
		</p>
		<p class="vsosh2024-text">
			Задания школьного этапа:
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">для предметов математика и русский язык разработаны для 4-11 класса.</li>
			<li class="vsosh2024-items">для предметов информатика, иностранный язык (английский, немецкий, французский,
				испанский, китайский), биология, экология, география, астрономия, литература, история, обществознание, право,
				физическая культура, технология, основы безопасности и защиты Родины разработаны для 5-11 класса.</li>
			<li class="vsosh2024-items">для предметов искусство, физика, экономика, химия разработаны для 7-11 класса.</li>
		</ul>
		<p class="vsosh2024-text">
			Задания муниципального этапа разработаны для 7-11 классов.
		</p>
		<p class="vsosh2024-text">
			Задания регионального этапа разработаны для 9-11 классов.
		</p>
		<p class="vsosh2024-text">
			Задания заключительного этапа разработаны для 9-11 классов.
		</p>

		<p class="JuniorProfi-sub-title">
			Льготы победителям и призерам ВСОШ при поступлении в вузы:
		</p>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">Главная льгота, которую предоставляет победа или призерство на заключительном этапе
				всероссийской олимпиады школьников – это поступление в профильные вузы без экзаменов (БВИ).</li>
			<li class="vsosh2024-items">Если победитель поступает на непрофильную специальность, по решению вуза ему могут
				зачесть 100 баллов за ЕГЭ по предмету, соответствующему профилю олимпиады (соответствие направления и профиля
				олимпиады определяет сам вуз).</li>
			<li class="vsosh2024-items">Для победителей и призеров регионального этапа ВСОШ есть возможность получения
				баллов индивидуальных достижений, дополнительно к сумме баллов ЕГЭ. Порядок учета и перечень индивидуальных
				достижений устанавливается каждым вузом самостоятельно.</li>
		</ul>

	</div>


	<!-- Архив -->
	<ArchiveVsosh />

</template>

<script>
import ArchiveVsosh from '@/components/ArchiveVsosh.vue';

export default {
	components: {
		ArchiveVsosh,
	}
}

</script>