<template>
	<div class="BestCounselor _cover">
		<h2 class="BestCounselor-title _cover-title">
			Региональный конкурс<br> «Лучший вожатый-2024»
		</h2>

		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/LVLogo.webp" alt="Логотип" />
		</div>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Цель конкурса:</b> конкурс направлен на выявление, анализ и трансляцию лучших вожатских практик, повышение
			педагогического мастерства и методической грамотности вожатых, самореализации способностей для повышения качества
			организации отдыха и оздоровления детей.
		</p>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Формат проведения конкурса:</b> дистанционный, в один этап.
		</p>

		<p class="GoldenFundOfSiberia-sub-title">
			<b>Участники:</b> к участию приглашаются вожатые образовательных организаций, в возрасте от 16 лет, имеющие опыт
			работы воспитательной деятельности не менее трех месяцев.
		</p>

		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%9B%D1%83%D1%87%D1%88%D0%B8%D0%B9-%D0%B2%D0%BE%D0%B6%D0%B0%D1%82%D1%8B%D0%B9.pdf"
					target="_blank">
					Письмо Лучший вожатый
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/10/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%9B%D1%83%D1%87%D1%88%D0%B8%D0%B9-%D0%B2%D0%BE%D0%B6%D0%B0%D1%82%D1%8B%D0%B9-24.pdf"
					target="_blank">
					Распоряжение Лучший вожатый
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Как участвовать:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Прием конкурсных материалов осуществляет ОЦ «Персей» с 14 октября 2024 года по 17 ноября 2024 года включительно.
			</li>
			<li class="JuniorProfi-items">
				Для участия в Конкурсе участнику необходимо подать конкурсные материалы в АИС - <a style="color:blue"
					href="konkurs.ric38.ru">konkurs.ric38.ru</a>.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Конкурсные материалы:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Заявление на участие (Приложение 1 Положения).
			</li>
			<li class="JuniorProfi-items">
				Портфолио участника Конкурса: анкета (Приложение 2 Положения) и документы, подтверждающие достижения вожатого
				за последние 2 года (грамоты, благодарственные письма, дипломы, не более 10 штук, не ранее сентября 2022 года,
				сканируются единым файлом).
			</li>
			<li class="JuniorProfi-items">
				Видеоролик «Вожатский мастер-класс», продолжительностью до 10 минут, который представляет из себя фрагмент
				мероприятия или занятия по одному из направлений вожатской деятельности:
				<ul class="JuniorProfi-list">
					<li class="marker-2">
						гражданско-патриотическое воспитание;
					</li>
					<li class="marker-2">
						организация коллективно-творческих дел;
					</li>
					<li class="marker-2">
						формирование здорового образа жизни;
					</li>
					<li class="marker-2">
						игротека с детьми разного возраста;
					</li>
					<li class="marker-2">
						организация творческих конкурсов и мероприятий.
					</li>
				</ul>
			</li>
			<li class="JuniorProfi-items">
				Эссе. Тема для Эссе будет размещена 12 ноября 2024 года на данной странице.
			</li>
			<li class="JuniorProfi-items">
				Ответы на Кейсы. Задания по вопросу Кейсы будут размещены 13 ноября 2024 года на данной странице.
			</li>
			<li class="JuniorProfi-items">
				Полная информация о документах и критериях оценки представлена в Положении.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Подведение итогов:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Информация о результатах Конкурса размещается на данной странице в срок до 20 января 2024 года.
			</li>
			<li class="JuniorProfi-items">
				Дипломы и сертификаты направляются на электронную почту победителям и участникам Конкурса не позднее 16
				февраля 2025 года.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 54-60-44 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>
	</div>

	<!-- Архив -->
	<ArchiveBestCounselor />

</template>
		
<script>
import ArchiveBestCounselor from '@/components/ArchiveBestCounselor.vue';

export default {
	components: {
		ArchiveBestCounselor,
	}
}

</script>

<style>

	ul.marker-2 {
		list-style: none;
		margin-bottom: 5px;
	}

	ul li.marker-2:before {
		content: "—";
		position: relative;
		left: -5px;
	}

	.marker-2 {
		margin: 20px 0 0 30px
	}

</style>