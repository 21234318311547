<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Спортивное ориентирование»
		</h2>
		<p class="EventsPage-date">
			с 26 по 30 сентября 2024 года
		</p>
		<p class="EventsPage-date">
			с 02 по 06 октября 2024 года
		</p>
		<p class="EventsPage-date">
			с 08 по 12 октября 2024 года
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25661-programma-sportivnoe-orientirovanie"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит профильную смену «Спортивное ориентирование» для обучающихся 5-11-х
			классов общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Программа позволит учащимся углубить и совершенствовать свои знания, умения и навыки в спортивном ориентировании.
			Практические занятия на свежем воздухе в командном формате способствуют совершенствованию коммуникационных и
			социальных навыков, сотрудничества и взаимопомощи.
		</p>

		<p class="EventsPage-text">
			Ориентирование на местности сочетает в себе физические и умственные нагрузки на фоне положительных эмоций в
			постоянно меняющихся внешних условиях, а также требует от спортсменов быстрой и точной оценки сложившейся ситуации
			и умения мыслить в условиях больших физических нагрузок. Занятия ориентированием вырабатывают самостоятельность,
			решительность, целеустремленность, настойчивость, умение владеть собой, быструю реакцию.
		</p>

		<p class="EventsPage-text">
			Спортивное ориентирование — это и познание, и повышение культурного уровня, и важное средство общения, и дружба, и
			укрепление здоровья учащихся, и немало важно - любовь к своей малой родине, природе и истории.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты участников Смены.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Павлов Андрей Николаевич, старший преподаватель спортивно-оздоровительного комплекса юридического института
				Иркутского государственного университета, спортивный судья всероссийской категории, мастер спорта России по
				спортивному ориентированию
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Участникам, прошедших на профильную смену необходимо подать заявку на платформе Навигатор дополнительного
				образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/PaWXDrgRrtYY7Q" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Стягайло Екатерина Станиславна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: e.kapustina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>