<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>

		<div class="AllRussian-img-box" style="max-width: 500px;">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>

		<h2 class="EventsPage-title">
			«Олимпиадная математика»
		</h2>
		<p class="EventsPage-date">
			21 октября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=201" target="_blank">
			Регистрация
		</a>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->

		<p class="JuniorProfi-sub-title">
			Описание курса:
		</p>
		<p class="EventsPage-text">
			Программа «Олимпиадная математика» ориентирована на подготовку обучающихся к всероссийской олимпиаде школьников. В
			курсе 2 модуля: для 7-9 классов и 10-11 классов.
		</p>
		<p class="EventsPage-text">
			Обучение проходит на бесплатной основе, без вступительных испытаний.
		</p>

		<p class="JuniorProfi-sub-title">
			Программа для 7-9 классов:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Раздел 1. Методы решения логических задач. [Метод рассуждений. Табличный метод. Метод включения-исключения.
				Метод раскраски].
			</li>
			<li class="JuniorProfi-items">
				Раздел 2. Принцип Дирихле. [Отображение конечных множеств и принцип Дирихле. Принцип Дирихле в теории чисел.
				Геометрические аналоги принципа Дирихле. Непрерывный принцип Дирихле].
			</li>
			<li class="JuniorProfi-items">
				Раздел 3. Математика на шахматной доске. [Свойства шахматной доски. Особенности геометрии шахматной доски.
				Задачи на расстановку шахматных фигур. Расположения на шахматной доске. Задачи на расположения на шахматной
				доске. Кони, слоны, шашки и прочие фигуры. Независимость и доминирование шахматных фигур].
			</li>
			<li class="JuniorProfi-items">
				Раздел 4. Элементы теории графов. [Определение графа. Плоские графы. Деревья. Свойства графов].
			</li>
			<li class="JuniorProfi-items">
				Раздел 5. Итоговая аттестация.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Программа для 10-11 классов:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Раздел 1. Методы решения неравенств в целых числах. [Неравенства в целых числах. Решение неравенств в целых
				числах].
			</li>
			<li class="JuniorProfi-items">
				Раздел 2. Целая и дробная часть числа. [Целая и дробная часть числа. Уравнения с целой частью числа. Уравнения с
				дробной частью числа. Решение неравенств с целой и дробной частью числа].
			</li>
			<li class="JuniorProfi-items">
				Раздел 3. Инварианты и полуинварианты. [Инвариант. Применение инвариантов в логических задачах. Полуинвариант в
				логических задачах на операции и процессы].
			</li>
			<li class="JuniorProfi-items">
				Раздел 4. Отображения конечных множеств и принцип Дирихле. [Отображение конечных множеств. Принцип Дирихле.
				Принцип Дирихле в теории чисел. Геометрические аналоги принципа Дирихле. Непрерывный принцип Дирихле].
			</li>
			<li class="JuniorProfi-items">
				Раздел 5. Методы решения логических задач. [Метод включения-исключения. Метод раскраски].
			</li>
			<li class="JuniorProfi-items">
				Раздел 6. Итоговая аттестация.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Кузьмин Олег Викторович, доктор физико-математических наук, профессор, заведующий кафедрой теории вероятностей и
				дискретной математики ФГБОУ ВО «ИГУ»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 21 октября 2024 года на платформе Навигатор дополнительного образования детей Иркутской
				области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 21 октября 2024 года на платформе Moodle, Образовательного центра
				«Персей» <a style="color:blue" href="https://moodle.perseusirk.ru/course/view.php?id=201">ссылка на
					регистрацию</a>
			</li>
			<li class="JuniorProfi-items">
				<a style="color:blue"
					href="https://р38.навигатор.дети/program/26986-programma-olimpiadnaya-matematika-7-9-klass">
					Ссылка на подачу заявки в Навигатор, 7-9 класс
				</a>
			</li>
			<li class="JuniorProfi-items">
				<a style="color:blue"
					href="https://р38.навигатор.дети/program/26997-programma-olimpiadnaya-matematika-10-11-klass">
					Ссылка на подачу заявки в Навигатор, 10-11 класс
				</a>
			</li>
		</ul>


		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%D0%BF%D0%BE-%D0%B4%D0%B8%D1%81%D1%82.-%D0%BA%D1%83%D1%80%D1%81%D0%B0%D0%BC-%D0%92%D0%A1%D0%9E%D0%A8.pdf"
					target="_blank">
					Письмо по дист. курсам ВСОШ
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Гавриленко Татьяна Георгиевна
			</li>
			<li class="JuniorProfi-items">
				Непомнящая Екатерина Сергеевна
			</li>
			<li class="JuniorProfi-items">
				Бутакова Светлана Владимировна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044 (доб. 4)
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: perseus@perseusirk.ru
			</li>
		</ul>

	</div>
</template>