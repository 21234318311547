<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Развитие мягких навыков»
		</h2>
		<p class="EventsPage-date">
			с 28 октября по 1 ноября 2024 года
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Развитие мягких навыков» для учащихся 8-10-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Развитие мягких навыков необходимы как для повышения успеваемости, постановки жизненных целей, осознанного участия
			в дополнительных общеобразовательных проектах, так и для формирования базы для последующего развития необходимых
			профессиональных навыков, профессиональной самореализации и повышению качества трудовых отношений как внутри
			коллектива, так и в отношениях Сотрудник - Работодатель.
		</p>

		<p class="EventsPage-text">
			Программа поможет учащимся пополнить знания, освоить техники и инструменты развития мышления, составить
			представление об основных направлениях в области формирования softskills.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Вокина Надежда Николаевна, методист образовательной студии «Школа космонавтов», тренер мягких навыков,
				профориентатор;
			</li>
			<li class="JuniorProfi-items">
				Перевозникова Мария Юрьевна - руководитель образовательной студии «Школа космонавтов»;
			</li>
			<li class="JuniorProfi-items">
				Долгих Любовь Александровна - психолог образовательной студии «Школа космонавтов».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Записаться на программу «Развитие мягких навыков» на ресурсе «Навигатор дополнительного образования Иркутской
				области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 28 октября по 1 ноября 2024 года на бесплатной основе в Образовательном центре
				«Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>