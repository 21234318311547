import { createWebHistory, createRouter } from 'vue-router'

import Main from '@/page/Main.vue'
import ArtBlock from '@/page/ArtBlock.vue'
import Contacts from '@/page/Contacts.vue'
import Documentation from '@/page/Documentation.vue'
import ExpertCouncil from '@/page/ExpertCouncil.vue'
import GetIn from '@/page/GetIn.vue'
import Intelligence from '@/page/Intelligence.vue'
import StructureAndOrgans from '@/page/StructureAndOrgans.vue'
import Logistics from '@/page/Logistics.vue'
import VacanciesForAdmission from '@/page/VacanciesForAdmission.vue'
import Scholarships from '@/page/Scholarships.vue'
import Financial from '@/page/Financial.vue'
import Education from '@/page/Education.vue'
import Management from '@/page/Management.vue'
import News from '@/page/News.vue'
import BestPrograms from '@/page/BestPrograms.vue'
import NewsPage1 from '@/NewsPage/NewsPage1.vue'
import NewsPage2 from '@/NewsPage/NewsPage2.vue'
import NewsPage3 from '@/NewsPage/NewsPage3.vue'
import NewsPage4 from '@/NewsPage/NewsPage4.vue'
import NewsPage5 from '@/NewsPage/NewsPage5.vue'
import NewsPage6 from '@/NewsPage/NewsPage6.vue'
import NewsPage7 from '@/NewsPage/NewsPage7.vue'
import NewsPage8 from '@/NewsPage/NewsPage8.vue'
import NewsPage9 from '@/NewsPage/NewsPage9.vue'
import NewsPage10 from '@/NewsPage/NewsPage10.vue'
import NewsPage11 from '@/NewsPage/NewsPage11.vue'
import NewsPage12 from '@/NewsPage/NewsPage12.vue'
import NewsPage13 from '@/NewsPage/NewsPage13.vue'
import NewsPage14 from '@/NewsPage/NewsPage14.vue'
import NewsPage15 from '@/NewsPage/NewsPage15.vue'
import NewsPage16 from '@/NewsPage/NewsPage16.vue'
import NewsPage17 from '@/NewsPage/NewsPage17.vue'
import NewsPage18 from '@/NewsPage/NewsPage18.vue'
import NewsPage19 from '@/NewsPage/NewsPage19.vue'
import NewsPage20 from '@/NewsPage/NewsPage20.vue'
import NewsPage21 from '@/NewsPage/NewsPage21.vue'
import NewsPage22 from '@/NewsPage/NewsPage22.vue'
import NewsPage23 from '@/NewsPage/NewsPage23.vue'
import NewsPage24 from '@/NewsPage/NewsPage24.vue'
import NewsPage25 from '@/NewsPage/NewsPage25.vue'
import NewsPage26 from '@/NewsPage/NewsPage26.vue'
import NewsPage27 from '@/NewsPage/NewsPage27.vue'
import NewsPage28 from '@/NewsPage/NewsPage28.vue'
import NewsPage29 from '@/NewsPage/NewsPage29.vue'
import NewsPage30 from '@/NewsPage/NewsPage30.vue'
import NewsPage31 from '@/NewsPage/NewsPage31.vue'
import NewsPage32 from '@/NewsPage/NewsPage32.vue'
import NewsPage33 from '@/NewsPage/NewsPage33.vue'
import NewsPage34 from '@/NewsPage/NewsPage34.vue'
import NewsPage35 from '@/NewsPage/NewsPage35.vue'
import NewsPage36 from '@/NewsPage/NewsPage36.vue'
import NewsPage37 from '@/NewsPage/NewsPage37.vue'
import NewsPage38 from '@/NewsPage/NewsPage38.vue'
import NewsPage39 from '@/NewsPage/NewsPage39.vue'
import NewsPage40 from '@/NewsPage/NewsPage40.vue'
import NewsPage41 from '@/NewsPage/NewsPage41.vue'
import NewsPage42 from '@/NewsPage/NewsPage42.vue'
import NewsPage43 from '@/NewsPage/NewsPage43.vue'
import NewsPage44 from '@/NewsPage/NewsPage44.vue'
import NewsPage45 from '@/NewsPage/NewsPage45.vue'
import NewsPage46 from '@/NewsPage/NewsPage46.vue'
import NewsPage47 from '@/NewsPage/NewsPage47.vue'
import NewsPage48 from '@/NewsPage/NewsPage48.vue'
import NewsPage49 from '@/NewsPage/NewsPage49.vue'
import NewsPage50 from '@/NewsPage/NewsPage50.vue'
import NewsPage51 from '@/NewsPage/NewsPage51.vue'
import NewsPage52 from '@/NewsPage/NewsPage52.vue'
import NewsPage53 from '@/NewsPage/NewsPage53.vue'
import NewsPage54 from '@/NewsPage/NewsPage54.vue'
import NewsPage55 from '@/NewsPage/NewsPage55.vue'
import NewsPage56 from '@/NewsPage/NewsPage56.vue'
import NewsPage57 from '@/NewsPage/NewsPage57.vue'
import NewsPage58 from '@/NewsPage/NewsPage58.vue'
import NewsPage59 from '@/NewsPage/NewsPage59.vue'
import NewsPage60 from '@/NewsPage/NewsPage60.vue'
import NewsPage61 from '@/NewsPage/NewsPage61.vue'
import NewsPage62 from '@/NewsPage/NewsPage62.vue'
import NewsPage63 from '@/NewsPage/NewsPage63.vue'
import NewsPage64 from '@/NewsPage/NewsPage64.vue'
import NewsPage65 from '@/NewsPage/NewsPage65.vue'
import NewsPage66 from '@/NewsPage/NewsPage66.vue'
import NewsPage67 from '@/NewsPage/NewsPage67.vue'
import NewsPage68 from '@/NewsPage/NewsPage68.vue'
import NewsPage69 from '@/NewsPage/NewsPage69.vue'
import NewsPage70 from '@/NewsPage/NewsPage70.vue'
import NewsPage71 from '@/NewsPage/NewsPage71.vue'
import NewsPage72 from '@/NewsPage/NewsPage72.vue'
import NewsPage73 from '@/NewsPage/NewsPage73.vue'
import NewsPage74 from '@/NewsPage/NewsPage74.vue'
import NewsPage75 from '@/NewsPage/NewsPage75.vue'
import NewsPage76 from '@/NewsPage/NewsPage76.vue'
import NewsPage77 from '@/NewsPage/NewsPage77.vue'
import NewsPage78 from '@/NewsPage/NewsPage78.vue'
import NewsPage79 from '@/NewsPage/NewsPage79.vue'
import NewsPage80 from '@/NewsPage/NewsPage80.vue'
import NewsPage81 from '@/NewsPage/NewsPage81.vue'
import NewsPage82 from '@/NewsPage/NewsPage82.vue'
import NewsPage83 from '@/NewsPage/NewsPage83.vue'
import OlyandCom from '@/page/OlyandCom.vue'
import Partners from '@/page/Partners.vue'
import ProfileBlock from '@/page/ProfileBlock.vue'
import Registration from '@/page/Registration.vue'
import JuniorProfi from '@/page/JuniorProfi.vue'
import RemoteBlock from '@/page/RemoteBlock.vue'
import ScienceBlock from '@/page/ScienceBlock.vue'
import Sing from '@/page/Sing.vue'
import AllRussian from '@/page/AllRussian.vue'
import AdditionalGneral from '@/page/AdditionalGneral.vue'
import AdditionalGneral2023 from '@/page/AdditionalGneral2023.vue'
import AdditionalGneral2024 from '@/page/AdditionalGneral2024.vue'
import AdditionalProfessionalPrograms from '@/page/AdditionalProfessionalPrograms.vue'
import AdditionalProfessionalPrograms2023 from '@/page/AdditionalProfessionalPrograms2023.vue'
import AdditionalProfessionalPrograms2024 from '@/page/AdditionalProfessionalPrograms2024.vue'
import SportBlock from '@/page/SportBlock.vue'
import Trustees from '@/page/Trustees.vue'
import HowToGet from '@/page/HowToGet.vue'
import BasicInformation from '@/page/BasicInformation.vue'
import EventsPage1 from '../EventsPage/EventsPage1.vue'
import EventsPage2 from '../EventsPage/EventsPage2.vue'
import EventsPage3 from '../EventsPage/EventsPage3.vue'
import EventsPage4 from '../EventsPage/EventsPage4.vue'
import EventsPage5 from '../EventsPage/EventsPage5.vue'
import EventsPage6 from '../EventsPage/EventsPage6.vue'
import EventsPage7 from '../EventsPage/EventsPage7.vue'
import EventsPage8 from '../EventsPage/EventsPage8.vue'
import EventsPage9 from '../EventsPage/EventsPage9.vue'
import EventsPage10 from '../EventsPage/EventsPage10.vue'
import EventsPage11 from '../EventsPage/EventsPage11.vue'
import EventsPage12 from '../EventsPage/EventsPage12.vue'
import EventsPage13 from '../EventsPage/EventsPage13.vue'
import EventsPage14 from '../EventsPage/EventsPage14.vue'
import EventsPage15 from '../EventsPage/EventsPage15.vue'
import EventsPage16 from '../EventsPage/EventsPage16.vue'
import EventsPage17 from '../EventsPage/EventsPage17.vue'
import EventsPage18 from '../EventsPage/EventsPage18.vue'
import EventsPage19 from '../EventsPage/EventsPage19.vue'
import EventsPage20 from '../EventsPage/EventsPage20.vue'
import EventsPage21 from '../EventsPage/EventsPage21.vue'
import EventsPage22 from '../EventsPage/EventsPage22.vue'
import EventsPage23 from '../EventsPage/EventsPage23.vue'
import EventsPage24 from '../EventsPage/EventsPage24.vue'
import EventsPage25 from '../EventsPage/EventsPage25.vue'
import EventsPage26 from '../EventsPage/EventsPage26.vue'
import EventsPage27 from '../EventsPage/EventsPage27.vue'
import EventsPage28 from '../EventsPage/EventsPage28.vue'
import EventsPage29 from '../EventsPage/EventsPage29.vue'
import EventsPage30 from '../EventsPage/EventsPage30.vue'
import EventsPage31 from '../EventsPage/EventsPage31.vue'
import EventsPage32 from '../EventsPage/EventsPage32.vue'
import EventsPage33 from '../EventsPage/EventsPage33.vue'
import EventsPage36 from '../EventsPage/EventsPage36.vue'
import EventsPage37 from '../EventsPage/EventsPage37.vue'
import EventsPage38 from '../EventsPage/EventsPage38.vue'
import EventsPage39 from '../EventsPage/EventsPage39.vue'
import EventsPage40 from '../EventsPage/EventsPage40.vue'
import EventsPage41 from '../EventsPage/EventsPage41.vue'
import EventsPage42 from '../EventsPage/EventsPage42.vue'
import EventsPage46 from '../EventsPage/EventsPage46.vue'
import EventsPage47 from '../EventsPage/EventsPage47.vue'
import EventsPage48 from '../EventsPage/EventsPage48.vue'
import EventsPage49 from '../EventsPage/EventsPage49.vue'
import EventsPage50 from '../EventsPage/EventsPage50.vue'
import EventsPage51 from '../EventsPage/EventsPage51.vue'
import EventsPage52 from '../EventsPage/EventsPage52.vue'
import EventsPage53 from '../EventsPage/EventsPage53.vue'
import EventsPage54 from '../EventsPage/EventsPage54.vue'
import EventsPage55 from '../EventsPage/EventsPage55.vue'
import EventsPage56 from '../EventsPage/EventsPage56.vue'
import EventsPage57 from '../EventsPage/EventsPage57.vue'
import EventsPage58 from '../EventsPage/EventsPage58.vue'
import EventsPage59 from '../EventsPage/EventsPage59.vue'
import EventsPage90 from '../EventsPage/EventsPage90.vue'
import EventsPage91 from '../EventsPage/EventsPage91.vue'
import EventsPage92 from '../EventsPage/EventsPage92.vue'
import EventsPage93 from '../EventsPage/EventsPage93.vue'
import EventsPage94 from '../EventsPage/EventsPage94.vue'
import EventsPage95 from '../EventsPage/EventsPage95.vue'
import EventsPage96 from '../EventsPage/EventsPage96.vue'
import EventsPage97 from '../EventsPage/EventsPage97.vue'
import EventsPage98 from '../EventsPage/EventsPage98.vue'
import EventsPage99 from '../EventsPage/EventsPage99.vue'
import EventsPage100 from '../EventsPage/EventsPage100.vue'
import EventsPage102 from '../EventsPage/EventsPage102.vue'
import EventsPage103 from '../EventsPage/EventsPage103.vue'
import EventsPage104 from '../EventsPage/EventsPage104.vue'
import EventsPage105 from '../EventsPage/EventsPage105.vue'
import EventsPage106 from '../EventsPage/EventsPage106.vue'
import EventsPage107 from '../EventsPage/EventsPage107.vue'
import EventsPage108 from '../EventsPage/EventsPage108.vue'
import EventsPage109 from '../EventsPage/EventsPage109.vue'
import EventsPage111 from '../EventsPage/EventsPage111.vue'
import EventsPage112 from '../EventsPage/EventsPage112.vue'
import EventsPage114 from '../EventsPage/EventsPage114.vue'
import EventsPage115 from '../EventsPage/EventsPage115.vue'
import EventsPage116 from '../EventsPage/EventsPage116.vue'
import EventsPage117 from '../EventsPage/EventsPage117.vue'
import EventsPage118 from '../EventsPage/EventsPage118.vue'
import EventsPage119 from '../EventsPage/EventsPage119.vue'
import EventsPage120 from '../EventsPage/EventsPage120.vue'
import EventsPage121 from '../EventsPage/EventsPage121.vue'
import EventsPage122 from '../EventsPage/EventsPage122.vue'
import EventsPage123 from '../EventsPage/EventsPage123.vue'
import EventsPage124 from '../EventsPage/EventsPage124.vue'
import EventsPage125 from '../EventsPage/EventsPage125.vue'
import EventsPage126 from '../EventsPage/EventsPage126.vue'
import EventsPage127 from '../EventsPage/EventsPage127.vue'
import EventsPage128 from '../EventsPage/EventsPage128.vue'
import EventsPage129 from '../EventsPage/EventsPage129.vue'
import EventsPage131 from '../EventsPage/EventsPage131.vue'
import EventsPage132 from '../EventsPage/EventsPage132.vue'
import EventsPage133 from '../EventsPage/EventsPage133.vue'
import EventsPage135 from '../EventsPage/EventsPage135.vue'
import EventsPage136 from '../EventsPage/EventsPage136.vue'
import EventsPage137 from '../EventsPage/EventsPage137.vue'
import EventsPage138 from '../EventsPage/EventsPage138.vue'
import EventsPage139 from '../EventsPage/EventsPage139.vue'
import EventsPage140 from '../EventsPage/EventsPage140.vue'
import EventsPage141 from '../EventsPage/EventsPage141.vue'
import EventsPage142 from '../EventsPage/EventsPage142.vue'
import EventsPage143 from '../EventsPage/EventsPage143.vue'
import EventsPage144 from '../EventsPage/EventsPage144.vue'
import EventsPage145 from '../EventsPage/EventsPage145.vue'
import EventsPage146 from '../EventsPage/EventsPage146.vue'
import EventsPage147 from '../EventsPage/EventsPage147.vue'
import EventsPage148 from '../EventsPage/EventsPage148.vue'
import EventsPage149 from '../EventsPage/EventsPage149.vue'
import EventsPage150 from '../EventsPage/EventsPage150.vue'
import EventsPage151 from '../EventsPage/EventsPage151.vue'
import EventsPage152 from '../EventsPage/EventsPage152.vue'
import EventsPage153 from '../EventsPage/EventsPage153.vue'
import EventsPage154 from '../EventsPage/EventsPage154.vue'
import EventsPage156 from '../EventsPage/EventsPage156.vue'
import EventsPage157 from '../EventsPage/EventsPage157.vue'
import EventsPage158 from '../EventsPage/EventsPage158.vue'
import EventsPage159 from '../EventsPage/EventsPage159.vue'
import EventsPage160 from '../EventsPage/EventsPage160.vue'
import EventsPage161 from '../EventsPage/EventsPage161.vue'
import EventsPage162 from '../EventsPage/EventsPage162.vue'
import EventsPage163 from '../EventsPage/EventsPage163.vue'
import EventsPage164 from '../EventsPage/EventsPage164.vue'
import EventsPage165 from '../EventsPage/EventsPage165.vue'
import EventsPage166 from '../EventsPage/EventsPage166.vue'
import EventsPage167 from '../EventsPage/EventsPage167.vue'
import EventsPage168 from '../EventsPage/EventsPage168.vue'
import EventsPage169 from '../EventsPage/EventsPage169.vue'
import EventsPage170 from '../EventsPage/EventsPage170.vue'
import EventsPage171 from '../EventsPage/EventsPage171.vue'
import EventsPage172 from '../EventsPage/EventsPage172.vue'
import EventsPage173 from '../EventsPage/EventsPage173.vue'
import EventsPage174 from '../EventsPage/EventsPage174.vue'
import EventsPage175 from '../EventsPage/EventsPage175.vue'
import EventsPage176 from '../EventsPage/EventsPage176.vue'
import EventsPage177 from '../EventsPage/EventsPage177.vue'
import EventsPage178 from '../EventsPage/EventsPage178.vue'
import EventsPage179 from '../EventsPage/EventsPage179.vue'
import EventsPage180 from '../EventsPage/EventsPage180.vue'
import EventsPage181 from '../EventsPage/EventsPage181.vue'
import EventsPage182 from '../EventsPage/EventsPage182.vue'
import EventsPage183 from '../EventsPage/EventsPage183.vue'
import EventsPage184 from '../EventsPage/EventsPage184.vue'
import EventsPage185 from '../EventsPage/EventsPage185.vue'
import EventsPage186 from '../EventsPage/EventsPage186.vue'
import EventsPage187 from '../EventsPage/EventsPage187.vue'
import EventsPage188 from '../EventsPage/EventsPage188.vue'
import EventsPage189 from '../EventsPage/EventsPage189.vue'
import EventsPage190 from '../EventsPage/EventsPage190.vue'
import EventsPage191 from '../EventsPage/EventsPage191.vue'
import EventsPage192 from '../EventsPage/EventsPage192.vue'
import EventsPage193 from '../EventsPage/EventsPage193.vue'
import EventsPage194 from '../EventsPage/EventsPage194.vue'
import EventsPage195 from '../EventsPage/EventsPage195.vue'
import EventsPage196 from '../EventsPage/EventsPage196.vue'
import EventsPage197 from '../EventsPage/EventsPage197.vue'
import EventsPage198 from '../EventsPage/EventsPage198.vue'
import EventsPage199 from '../EventsPage/EventsPage199.vue'
import EventsPage200 from '../EventsPage/EventsPage200.vue'
import EventsPage201 from '../EventsPage/EventsPage201.vue'
import EventsPage202 from '../EventsPage/EventsPage202.vue'
import EventsPage203 from '../EventsPage/EventsPage203.vue'
import EventsPage204 from '../EventsPage/EventsPage204.vue'
import EventsPage205 from '../EventsPage/EventsPage205.vue'
import EventsPage206 from '../EventsPage/EventsPage206.vue'
import EventsPage207 from '../EventsPage/EventsPage207.vue'
import EventsPage208 from '../EventsPage/EventsPage208.vue'
import EventsPage209 from '../EventsPage/EventsPage209.vue'
import Preparation from '@/page/Preparation.vue'
import ContactsofOlympiad from '@/page/ContactsofOlympiad.vue'
import SchoolStage from '@/page/SchoolStage.vue'
import DistanceCourses from '@/page/DistanceCourses.vue'
import BigChallenges2023 from '@/page/BigChallenges2023.vue'
import BigChallenges2024 from '@/page/BigChallenges2024.vue'
import Pruffme from '@/page/Pruffme.vue'
import WeeklyCoursesScience from '@/page/WeeklyCoursesScience.vue'
import WeeklyCoursesArt from '@/page/WeeklyCoursesArt.vue'
import Training from '@/page/Training.vue'
import ProfessionalEducation from '@/page/ProfessionalEducation.vue'
import ConferenceSeminars from '@/page/ConferenceSeminars.vue'
import SelectionCriteria from '@/page/SelectionCriteria.vue'
import PaidServices from '@/page/PaidServices.vue'
import VacantPlaces from '@/page/VacantPlaces.vue'
import InternatiCooperation from '@/page/InternatiCooperation.vue'
import Sirius_Summer from '@/page/Sirius_Summer.vue'
import Sirius_Artificial_Intelligence from '@/page/Sirius_Artificial_Intelligence.vue'
import Sirius_Lessons from '@/page/Sirius_Lessons.vue'
import SummerHolidayRecommendations from '@/page/SummerHolidayRecommendations.vue'
import RecommendationsForTeachers from '@/page/RecommendationsForTeachers.vue'
import PsychologicalSupport from '@/page/PsychologicalSupport.vue'
import VsoshPage from '@/page/VsoshPage.vue'
import GoldenFundPage from '@/page/GoldenFundPage.vue'
import OlympicPage from '@/page/OlympicPage.vue'
import HighestQualityPage from '@/page/HighestQualityPage.vue'
import TalentAndSuccessFoundationPage from '@/page/TalentAndSuccessFoundationPage.vue'
import BigChallengesPage from '@/page/BigChallengesPage.vue'
import JuniorProfiChampionshipPage from '@/page/JuniorProfiChampionshipPage.vue'
import SmartiesAndSmartiesPage from '@/page/SmartiesAndSmartiesPage.vue'
import BestStudentPage from '@/page/BestStudentPage.vue'
import BestCounselorPage from '@/page/BestCounselorPage.vue'
import BestPracticsPage from '@/page/BestPracticsPage.vue'
import MunicipalCoordinators from '@/page/MunicipalCoordinators.vue'
import CompetitionsForTeachers from '@/page/CompetitionsForTeachers.vue'
import TeachingStaff from '@/page/TeachingStaff.vue'
import Nutrition from '@/page/Nutrition.vue'
import EducationalStandards from '@/page/EducationalStandards.vue'
import RegulatoryDocuments from '@/page/RegulatoryDocuments.vue'
import VsoshDocuments from '@/page/VsoshDocuments.vue'
import OtherEvents from '@/page/OtherEvents.vue'
import test from '@/page/test.vue'

const routes = [
	{
		path: '/',
		name: 'Main',
		component: Main,
	},
	{
		path: '/Pruffme',
		name: 'Pruffme',
		component: Pruffme,
	},
	{
		path: '/ArtBlock',
		name: 'Art',
		component: ArtBlock,
	},
	{
		path: '/Contacts',
		name: 'Contacts',
		component: Contacts,
	},
	{
		path: '/Documentation',
		name: 'Documentation',
		component: Documentation,
	},
	{
		path: '/ExpertCouncil',
		name: 'ExpertCouncil',
		component: ExpertCouncil,
	},
	{
		path: '/GetIn',
		name: 'GetIn',
		component: GetIn,
	},
	{
		path: '/Intelligence',
		name: 'Intelligence',
		component: Intelligence,
	},
	{
		path: '/StructureAndOrgans',
		name: 'StructureAndOrgans',
		component: StructureAndOrgans,
	},
	{
		path: '/Logistics',
		name: 'Logistics',
		component: Logistics,
	},
	{
		path: '/VacanciesForAdmission',
		name: 'VacanciesForAdmission',
		component: VacanciesForAdmission,
	},
	{
		path: '/Scholarships',
		name: 'Scholarships',
		component: Scholarships,
	},
	{
		path: '/Financial',
		name: 'Financial',
		component: Financial,
	},
	{
		path: '/Education',
		name: 'Education',
		component: Education,
	},
	{
		path: '/Management',
		name: 'Management',
		component: Management,
	},
	{
		path: '/Preparation',
		name: 'Preparation',
		component: Preparation,
	},
	{
		path: '/ContactsofOlympiad',
		name: 'ContactsofOlympiad',
		component: ContactsofOlympiad,
	},
	{
		path: '/BigChallenges2023',
		name: 'BigChallenges2023',
		component: BigChallenges2023,
	},
	{
		path: '/BigChallenges2024',
		name: 'BigChallenges2024',
		component: BigChallenges2024,
	},
	{
		path: '/DistanceCourses',
		name: 'DistanceCourses',
		component: DistanceCourses,
	},
	{
		path: '/SchoolStage',
		name: 'SchoolStage',
		component: SchoolStage,
	},
	{
		path: '/News',
		name: 'News',
		component: News,
	},
	{
		path: '/BestPrograms',
		name: 'BestPrograms',
		component: BestPrograms,
	},
	{
		path: '/News/NewsPage1',
		name: 'NewsPage1',
		component: NewsPage1,
	},
	{
		path: '/News/NewsPage2',
		name: 'NewsPage2',
		component: NewsPage2,
	},
	{
		path: '/News/NewsPage3',
		name: 'NewsPage3',
		component: NewsPage3,
	},
	{
		path: '/News/NewsPage4',
		name: 'NewsPage4',
		component: NewsPage4,
	},
	{
		path: '/News/NewsPage5',
		name: 'NewsPage5',
		component: NewsPage5,
	},
	{
		path: '/News/NewsPage6',
		name: 'NewsPage6',
		component: NewsPage6,
	},
	{
		path: '/News/NewsPage7',
		name: 'NewsPage7',
		component: NewsPage7,
	},
	{
		path: '/News/NewsPage8',
		name: 'NewsPage8',
		component: NewsPage8,
	},
	{
		path: '/News/NewsPage9',
		name: 'NewsPage9',
		component: NewsPage9,
	},
	{
		path: '/News/NewsPage10',
		name: 'NewsPage10',
		component: NewsPage10,
	},
	{
		path: '/News/NewsPage11',
		name: 'NewsPage11',
		component: NewsPage11,
	},
	{
		path: '/News/NewsPage12',
		name: 'NewsPage12',
		component: NewsPage12,
	},
	{
		path: '/News/NewsPage13',
		name: 'NewsPage13',
		component: NewsPage13,
	},
	{
		path: '/News/NewsPage14',
		name: 'NewsPage14',
		component: NewsPage14,
	},
	{
		path: '/News/NewsPage15',
		name: 'NewsPage15',
		component: NewsPage15,
	},
	{
		path: '/News/NewsPage16',
		name: 'NewsPage16',
		component: NewsPage16,
	},
	{
		path: '/News/NewsPage17',
		name: 'NewsPage17',
		component: NewsPage17,
	},
	{
		path: '/News/NewsPage18',
		name: 'NewsPage18',
		component: NewsPage18,
	},
	{
		path: '/News/NewsPage19',
		name: 'NewsPage19',
		component: NewsPage19,
	},
	{
		path: '/News/NewsPage20',
		name: 'NewsPage20',
		component: NewsPage20,
	},
	{
		path: '/News/NewsPage21',
		name: 'NewsPage21',
		component: NewsPage21,
	},
	{
		path: '/News/NewsPage22',
		name: 'NewsPage22',
		component: NewsPage22,
	},
	{
		path: '/News/NewsPage23',
		name: 'NewsPage23',
		component: NewsPage23,
	},
	{
		path: '/News/NewsPage24',
		name: 'NewsPage24',
		component: NewsPage24,
	},
	{
		path: '/News/NewsPage25',
		name: 'NewsPage25',
		component: NewsPage25,
	},
	{
		path: '/News/NewsPage26',
		name: 'NewsPage26',
		component: NewsPage26,
	},
	{
		path: '/News/NewsPage27',
		name: 'NewsPage27',
		component: NewsPage27,
	},
	{
		path: '/News/NewsPage28',
		name: 'NewsPage28',
		component: NewsPage28,
	},
	{
		path: '/News/NewsPage29',
		name: 'NewsPage29',
		component: NewsPage29,
	},
	{
		path: '/News/NewsPage30',
		name: 'NewsPage30',
		component: NewsPage30,
	},
	{
		path: '/News/NewsPage31',
		name: 'NewsPage31',
		component: NewsPage31,
	},
	{
		path: '/News/NewsPage32',
		name: 'NewsPage32',
		component: NewsPage32,
	},
	{
		path: '/News/NewsPage33',
		name: 'NewsPage33',
		component: NewsPage33,
	},
	{
		path: '/News/NewsPage34',
		name: 'NewsPage34',
		component: NewsPage34,
	},
	{
		path: '/News/NewsPage35',
		name: 'NewsPage35',
		component: NewsPage35,
	},
	{
		path: '/News/NewsPage36',
		name: 'NewsPage36',
		component: NewsPage36,
	},
	{
		path: '/News/NewsPage37',
		name: 'NewsPage37',
		component: NewsPage37,
	},
	{
		path: '/News/NewsPage38',
		name: 'NewsPage38',
		component: NewsPage38,
	},
	{
		path: '/News/NewsPage39',
		name: 'NewsPage39',
		component: NewsPage39,
	},
	{
		path: '/News/NewsPage40',
		name: 'NewsPage40',
		component: NewsPage40,
	},
	{
		path: '/News/NewsPage41',
		name: 'NewsPage41',
		component: NewsPage41,
	},
	{
		path: '/News/NewsPage42',
		name: 'NewsPage42',
		component: NewsPage42,
	},
	{
		path: '/News/NewsPage43',
		name: 'NewsPage43',
		component: NewsPage43,
	},
	{
		path: '/News/NewsPage44',
		name: 'NewsPage44',
		component: NewsPage44,
	},
	{
		path: '/News/NewsPage45',
		name: 'NewsPage45',
		component: NewsPage45,
	},
	{
		path: '/News/NewsPage46',
		name: 'NewsPage46',
		component: NewsPage46,
	},
	{
		path: '/News/NewsPage47',
		name: 'NewsPage47',
		component: NewsPage47,
	},
	{
		path: '/News/NewsPage48',
		name: 'NewsPage48',
		component: NewsPage48,
	},
	{
		path: '/News/NewsPage49',
		name: 'NewsPage49',
		component: NewsPage49,
	},
	{
		path: '/News/NewsPage50',
		name: 'NewsPage50',
		component: NewsPage50,
	},
	{
		path: '/News/NewsPage51',
		name: 'NewsPage51',
		component: NewsPage51,
	},
	{
		path: '/News/NewsPage52',
		name: 'NewsPage52',
		component: NewsPage52,
	},
	{
		path: '/News/NewsPage53',
		name: 'NewsPage53',
		component: NewsPage53,
	},
	{
		path: '/News/NewsPage54',
		name: 'NewsPage54',
		component: NewsPage54,
	},
	{
		path: '/News/NewsPage55',
		name: 'NewsPage55',
		component: NewsPage55,
	},
	{
		path: '/News/NewsPage56',
		name: 'NewsPage56',
		component: NewsPage56,
	},
	{
		path: '/News/NewsPage57',
		name: 'NewsPage57',
		component: NewsPage57,
	},
	{
		path: '/News/NewsPage58',
		name: 'NewsPage58',
		component: NewsPage58,
	},
	{
		path: '/News/NewsPage59',
		name: 'NewsPage59',
		component: NewsPage59,
	},
	{
		path: '/News/NewsPage60',
		name: 'NewsPage60',
		component: NewsPage60,
	},
	{
		path: '/News/NewsPage61',
		name: 'NewsPage61',
		component: NewsPage61,
	},
	{
		path: '/News/NewsPage62',
		name: 'NewsPage62',
		component: NewsPage62,
	},
	{
		path: '/News/NewsPage63',
		name: 'NewsPage63',
		component: NewsPage63,
	},
	{
		path: '/News/NewsPage64',
		name: 'NewsPage64',
		component: NewsPage64,
	},
	{
		path: '/News/NewsPage65',
		name: 'NewsPage65',
		component: NewsPage65,
	},
	{
		path: '/News/NewsPage66',
		name: 'NewsPage66',
		component: NewsPage66,
	},
	{
		path: '/News/NewsPage67',
		name: 'NewsPage67',
		component: NewsPage67,
	},
	{
		path: '/News/NewsPage68',
		name: 'NewsPage68',
		component: NewsPage68,
	},
	{
		path: '/News/NewsPage69',
		name: 'NewsPage69',
		component: NewsPage69,
	},
	{
		path: '/News/NewsPage70',
		name: 'NewsPage70',
		component: NewsPage70,
	},
	{
		path: '/News/NewsPage71',
		name: 'NewsPage71',
		component: NewsPage71,
	},
	{
		path: '/News/NewsPage72',
		name: 'NewsPage72',
		component: NewsPage72,
	},
	{
		path: '/News/NewsPage73',
		name: 'NewsPage73',
		component: NewsPage73,
	},
	{
		path: '/News/NewsPage74',
		name: 'NewsPage74',
		component: NewsPage74,
	},
	{
		path: '/News/NewsPage75',
		name: 'NewsPage75',
		component: NewsPage75,
	},
	{
		path: '/News/NewsPage76',
		name: 'NewsPage76',
		component: NewsPage76,
	},
	{
		path: '/News/NewsPage77',
		name: 'NewsPage77',
		component: NewsPage77,
	},
	{
		path: '/News/NewsPage78',
		name: 'NewsPage78',
		component: NewsPage78,
	},
	{
		path: '/News/NewsPage79',
		name: 'NewsPage79',
		component: NewsPage79,
	},
	{
		path: '/News/NewsPage80',
		name: 'NewsPage80',
		component: NewsPage80,
	},
	{
		path: '/News/NewsPage81',
		name: 'NewsPage81',
		component: NewsPage81,
	},
	{
		path: '/News/NewsPage82',
		name: 'NewsPage82',
		component: NewsPage82,
	},
	{
		path: '/News/NewsPage83',
		name: 'NewsPage83',
		component: NewsPage83,
	},
	{
		path: '/OlyandCom',
		name: 'OlyandCom',
		component: OlyandCom,
	},
	{
		path: '/Partners',
		name: 'Partners',
		component: Partners,
	},
	{
		path: '/ProfileBlock',
		name: 'Profile',
		component: ProfileBlock,
	},
	{
		path: '/ProfileBlock/EventsPage1',
		name: 'EventsPage1',
		component: EventsPage1,
	},
	{
		path: '/rk-lv-2023',
		name: 'EventsPage2',
		component: EventsPage2,
	},
	{
		path: '/ProfileBlock/EventsPage3',
		name: 'EventsPage3',
		component: EventsPage3,
	},
	{
		path: '/ProfileBlock/EventsPage4',
		name: 'EventsPage4',
		component: EventsPage4,
	},
	{
		path: '/ProfileBlock/EventsPage5',
		name: 'EventsPage5',
		component: EventsPage5,
	},
	{
		path: '/ProfileBlock/EventsPage6',
		name: 'EventsPage6',
		component: EventsPage6,
	},
	{
		path: '/ProfileBlock/EventsPage7',
		name: 'EventsPage7',
		component: EventsPage7,
	},
	{
		path: '/ProfileBlock/EventsPage8',
		name: 'EventsPage8',
		component: EventsPage8,
	},
	{
		path: '/ProfileBlock/EventsPage9',
		name: 'EventsPage9',
		component: EventsPage9,
	},
	{
		path: '/ProfileBlock/EventsPage10',
		name: 'EventsPage10',
		component: EventsPage10,
	},
	{
		path: '/ProfileBlock/EventsPage11',
		name: 'EventsPage11',
		component: EventsPage11,
	},
	{
		path: '/ProfileBlock/EventsPage12',
		name: 'EventsPage12',
		component: EventsPage12,
	},
	{
		path: '/ProfileBlock/EventsPage13',
		name: 'EventsPage13',
		component: EventsPage13,
	},
	{
		path: '/ProfileBlock/EventsPage14',
		name: 'EventsPage14',
		component: EventsPage14,
	},
	{
		path: '/ProfileBlock/EventsPage15',
		name: 'EventsPage15',
		component: EventsPage15,
	},
	{
		path: '/ProfileBlock/EventsPage16',
		name: 'EventsPage16',
		component: EventsPage16,
	},
	{
		path: '/ProfileBlock/EventsPage17',
		name: 'EventsPage17',
		component: EventsPage17,
	},
	{
		path: '/ProfileBlock/EventsPage18',
		name: 'EventsPage18',
		component: EventsPage18,
	},
	{
		path: '/ProfileBlock/EventsPage19',
		name: 'EventsPage19',
		component: EventsPage19,
	},
	{
		path: '/ProfileBlock/EventsPage20',
		name: 'EventsPage20',
		component: EventsPage20,
	},
	{
		path: '/ProfileBlock/EventsPage21',
		name: 'EventsPage21',
		component: EventsPage21,
	},
	{
		path: '/ProfileBlock/EventsPage22',
		name: 'EventsPage22',
		component: EventsPage22,
	},
	{
		path: '/ProfileBlock/EventsPage23',
		name: 'EventsPage23',
		component: EventsPage23,
	},
	{
		path: '/ProfileBlock/EventsPage24',
		name: 'EventsPage24',
		component: EventsPage24,
	},
	{
		path: '/ProfileBlock/EventsPage25',
		name: 'EventsPage25',
		component: EventsPage25,
	},
	{
		path: '/ProfileBlock/EventsPage26',
		name: 'EventsPage26',
		component: EventsPage26,
	},
	{
		path: '/ProfileBlock/EventsPage27',
		name: 'EventsPage27',
		component: EventsPage27,
	},
	{
		path: '/ProfileBlock/EventsPage28',
		name: 'EventsPage28',
		component: EventsPage28,
	},
	{
		path: '/ProfileBlock/EventsPage29',
		name: 'EventsPage29',
		component: EventsPage29,
	},
	{
		path: '/ProfileBlock/EventsPage30',
		name: 'EventsPage30',
		component: EventsPage30,
	},
	{
		path: '/ProfileBlock/EventsPage31',
		name: 'EventsPage31',
		component: EventsPage31,
	},
	{
		path: '/ProfileBlock/EventsPage32',
		name: 'EventsPage32',
		component: EventsPage32,
	},
	{
		path: '/ProfileBlock/EventsPage33',
		name: 'EventsPage33',
		component: EventsPage33,
	},
	{
		path: '/ProfileBlock/EventsPage36',
		name: 'EventsPage36',
		component: EventsPage36,
	},
	{
		path: '/ProfileBlock/EventsPage37',
		name: 'EventsPage37',
		component: EventsPage37,
	},
	{
		path: '/ProfileBlock/EventsPage38',
		name: 'EventsPage38',
		component: EventsPage38,
	},
	{
		path: '/ProfileBlock/EventsPage39',
		name: 'EventsPage39',
		component: EventsPage39,
	},
	{
		path: '/ProfileBlock/EventsPage40',
		name: 'EventsPage40',
		component: EventsPage40,
	},
	{
		path: '/ProfileBlock/EventsPage41',
		name: 'EventsPage41',
		component: EventsPage41,
	},
	{
		path: '/ProfileBlock/EventsPage42',
		name: 'EventsPage42',
		component: EventsPage42,
	},
	{
		path: '/ProfileBlock/EventsPage46',
		name: 'EventsPage46',
		component: EventsPage46,
	},
	{
		path: '/ProfileBlock/EventsPage47',
		name: 'EventsPage47',
		component: EventsPage47,
	},
	{
		path: '/ProfileBlock/EventsPage48',
		name: 'EventsPage48',
		component: EventsPage48,
	},
	{
		path: '/ProfileBlock/EventsPage49',
		name: 'EventsPage49',
		component: EventsPage49,
	},
	{
		path: '/ProfileBlock/EventsPage50',
		name: 'EventsPage50',
		component: EventsPage50,
	},
	{
		path: '/ProfileBlock/EventsPage51',
		name: 'EventsPage51',
		component: EventsPage51,
	},
	{
		path: '/ProfileBlock/EventsPage52',
		name: 'EventsPage52',
		component: EventsPage52,
	},
	{
		path: '/ProfileBlock/EventsPage53',
		name: 'EventsPage53',
		component: EventsPage53,
	},
	{
		path: '/ProfileBlock/EventsPage54',
		name: 'EventsPage54',
		component: EventsPage54,
	},
	{
		path: '/ProfileBlock/EventsPage55',
		name: 'EventsPage55',
		component: EventsPage55,
	},
	{
		path: '/ProfileBlock/EventsPage56',
		name: 'EventsPage56',
		component: EventsPage56,
	},
	{
		path: '/ProfileBlock/EventsPage57',
		name: 'EventsPage57',
		component: EventsPage57,
	},
	{
		path: '/ProfileBlock/EventsPage58',
		name: 'EventsPage58',
		component: EventsPage58,
	},
	{
		path: '/ProfileBlock/EventsPage59',
		name: 'EventsPage59',
		component: EventsPage59,
	},
	{
		path: '/ProfileBlock/EventsPage90',
		name: 'EventsPage90',
		component: EventsPage90,
	},
	{
		path: '/ProfileBlock/EventsPage91',
		name: 'EventsPage91',
		component: EventsPage91,
	},
	{
		path: '/ProfileBlock/EventsPage92',
		name: 'EventsPage92',
		component: EventsPage92,
	},
	{
		path: '/ProfileBlock/EventsPage93',
		name: 'EventsPage93',
		component: EventsPage93,
	},
	{
		path: '/ProfileBlock/EventsPage94',
		name: 'EventsPage94',
		component: EventsPage94,
	},
	{
		path: '/ProfileBlock/EventsPage95',
		name: 'EventsPage95',
		component: EventsPage95,
	},
	{
		path: '/ProfileBlock/EventsPage96',
		name: 'EventsPage96',
		component: EventsPage96,
	},
	{
		path: '/ProfileBlock/EventsPage97',
		name: 'EventsPage97',
		component: EventsPage97,
	},
	{
		path: '/ProfileBlock/EventsPage98',
		name: 'EventsPage98',
		component: EventsPage98,
	},
	{
		path: '/ProfileBlock/EventsPage99',
		name: 'EventsPage99',
		component: EventsPage99,
	},
	{
		path: '/ProfileBlock/EventsPage100',
		name: 'EventsPage100',
		component: EventsPage100,
	},
	{
		path: '/ProfileBlock/EventsPage102',
		name: 'EventsPage102',
		component: EventsPage102,
	},
	{
		path: '/ProfileBlock/EventsPage103',
		name: 'EventsPage103',
		component: EventsPage103,
	},
	{
		path: '/ProfileBlock/EventsPage104',
		name: 'EventsPage104',
		component: EventsPage104,
	},
	{
		path: '/ProfileBlock/EventsPage105',
		name: 'EventsPage105',
		component: EventsPage105,
	},
	{
		path: '/ProfileBlock/EventsPage106',
		name: 'EventsPage106',
		component: EventsPage106,
	},
	{
		path: '/ProfileBlock/EventsPage107',
		name: 'EventsPage107',
		component: EventsPage107,
	},
	{
		path: '/ProfileBlock/EventsPage108',
		name: 'EventsPage108',
		component: EventsPage108,
	},
	{
		path: '/ProfileBlock/EventsPage109',
		name: 'EventsPage109',
		component: EventsPage109,
	},
	{
		path: '/ProfileBlock/EventsPage111',
		name: 'EventsPage111',
		component: EventsPage111,
	},
	{
		path: '/ProfileBlock/EventsPage112',
		name: 'EventsPage112',
		component: EventsPage112,
	},
	{
		path: '/ProfileBlock/EventsPage114',
		name: 'EventsPage114',
		component: EventsPage114,
	},
	{
		path: '/ProfileBlock/EventsPage115',
		name: 'EventsPage115',
		component: EventsPage115,
	},
	{
		path: '/ProfileBlock/EventsPage116',
		name: 'EventsPage116',
		component: EventsPage116,
	},
	{
		path: '/ProfileBlock/EventsPage117',
		name: 'EventsPage117',
		component: EventsPage117,
	},
	{
		path: '/ProfileBlock/EventsPage118',
		name: 'EventsPage118',
		component: EventsPage118,
	},
	{
		path: '/ProfileBlock/EventsPage119',
		name: 'EventsPage119',
		component: EventsPage119,
	},
	{
		path: '/ProfileBlock/EventsPage120',
		name: 'EventsPage120',
		component: EventsPage120,
	},
	{
		path: '/ProfileBlock/EventsPage121',
		name: 'EventsPage121',
		component: EventsPage121,
	},
	{
		path: '/ProfileBlock/EventsPage122',
		name: 'EventsPage122',
		component: EventsPage122,
	},
	{
		path: '/ProfileBlock/EventsPage123',
		name: 'EventsPage123',
		component: EventsPage123,
	},
	{
		path: '/ProfileBlock/EventsPage124',
		name: 'EventsPage124',
		component: EventsPage124,
	},
	{
		path: '/ProfileBlock/EventsPage125',
		name: 'EventsPage125',
		component: EventsPage125,
	},
	{
		path: '/ProfileBlock/EventsPage126',
		name: 'EventsPage126',
		component: EventsPage126,
	},
	{
		path: '/ProfileBlock/EventsPage127',
		name: 'EventsPage127',
		component: EventsPage127,
	},
	{
		path: '/ProfileBlock/EventsPage128',
		name: 'EventsPage128',
		component: EventsPage128,
	},
	{
		path: '/ProfileBlock/EventsPage129',
		name: 'EventsPage129',
		component: EventsPage129,
	},
	{
		path: '/ProfileBlock/EventsPage131',
		name: 'EventsPage131',
		component: EventsPage131,
	},
	{
		path: '/ProfileBlock/EventsPage132',
		name: 'EventsPage132',
		component: EventsPage132,
	},
	{
		path: '/ProfileBlock/EventsPage133',
		name: 'EventsPage133',
		component: EventsPage133,
	},
	{
		path: '/ProfileBlock/EventsPage135',
		name: 'EventsPage135',
		component: EventsPage135,
	},
	{
		path: '/ProfileBlock/EventsPage136',
		name: 'EventsPage136',
		component: EventsPage136,
	},
	{
		path: '/ProfileBlock/EventsPage137',
		name: 'EventsPage137',
		component: EventsPage137,
	},
	{
		path: '/ProfileBlock/EventsPage138',
		name: 'EventsPage138',
		component: EventsPage138,
	},
	{
		path: '/ProfileBlock/EventsPage139',
		name: 'EventsPage139',
		component: EventsPage139,
	},
	{
		path: '/ProfileBlock/EventsPage140',
		name: 'EventsPage140',
		component: EventsPage140,
	},
	{
		path: '/ProfileBlock/EventsPage141',
		name: 'EventsPage141',
		component: EventsPage141,
	},
	{
		path: '/ProfileBlock/EventsPage142',
		name: 'EventsPage142',
		component: EventsPage142,
	},
	{
		path: '/ProfileBlock/EventsPage143',
		name: 'EventsPage143',
		component: EventsPage143,
	},
	{
		path: '/ProfileBlock/EventsPage144',
		name: 'EventsPage144',
		component: EventsPage144,
	},
	{
		path: '/ProfileBlock/EventsPage145',
		name: 'EventsPage145',
		component: EventsPage145,
	},
	{
		path: '/ProfileBlock/EventsPage146',
		name: 'EventsPage146',
		component: EventsPage146,
	},
	{
		path: '/ProfileBlock/EventsPage147',
		name: 'EventsPage147',
		component: EventsPage147,
	},
	{
		path: '/ProfileBlock/EventsPage148',
		name: 'EventsPage148',
		component: EventsPage148,
	},
	{
		path: '/ProfileBlock/EventsPage149',
		name: 'EventsPage149',
		component: EventsPage149,
	},
	{
		path: '/ProfileBlock/EventsPage150',
		name: 'EventsPage150',
		component: EventsPage150,
	},
	{
		path: '/ProfileBlock/EventsPage151',
		name: 'EventsPage151',
		component: EventsPage151,
	},
	{
		path: '/ProfileBlock/EventsPage152',
		name: 'EventsPage152',
		component: EventsPage152,
	},
	{
		path: '/ProfileBlock/EventsPage153',
		name: 'EventsPage153',
		component: EventsPage153,
	},
	{
		path: '/ProfileBlock/EventsPage154',
		name: 'EventsPage154',
		component: EventsPage154,
	},
	{
		path: '/ProfileBlock/EventsPage156',
		name: 'EventsPage156',
		component: EventsPage156,
	},
	{
		path: '/ProfileBlock/EventsPage157',
		name: 'EventsPage157',
		component: EventsPage157,
	},
	{
		path: '/ProfileBlock/EventsPage158',
		name: 'EventsPage158',
		component: EventsPage158,
	},
	{
		path: '/ProfileBlock/EventsPage159',
		name: 'EventsPage159',
		component: EventsPage159,
	},
	{
		path: '/ProfileBlock/EventsPage160',
		name: 'EventsPage160',
		component: EventsPage160,
	},
	{
		path: '/ProfileBlock/EventsPage161',
		name: 'EventsPage161',
		component: EventsPage161,
	},
	{
		path: '/ProfileBlock/EventsPage162',
		name: 'EventsPage162',
		component: EventsPage162,
	},
	{
		path: '/ProfileBlock/EventsPage163',
		name: 'EventsPage163',
		component: EventsPage163,
	},
	{
		path: '/ProfileBlock/EventsPage164',
		name: 'EventsPage164',
		component: EventsPage164,
	},
	{
		path: '/ProfileBlock/EventsPage165',
		name: 'EventsPage165',
		component: EventsPage165,
	},
	{
		path: '/ProfileBlock/EventsPage166',
		name: 'EventsPage166',
		component: EventsPage166,
	},
	{
		path: '/ProfileBlock/EventsPage167',
		name: 'EventsPage167',
		component: EventsPage167,
	},
	{
		path: '/ProfileBlock/EventsPage168',
		name: 'EventsPage168',
		component: EventsPage168,
	},
	{
		path: '/ProfileBlock/EventsPage169',
		name: 'EventsPage169',
		component: EventsPage169,
	},
	{
		path: '/ProfileBlock/EventsPage170',
		name: 'EventsPage170',
		component: EventsPage170,
	},
	{
		path: '/ProfileBlock/EventsPage171',
		name: 'EventsPage171',
		component: EventsPage171,
	},
	{
		path: '/ProfileBlock/EventsPage172',
		name: 'EventsPage172',
		component: EventsPage172,
	},
	{
		path: '/ProfileBlock/EventsPage173',
		name: 'EventsPage173',
		component: EventsPage173,
	},
	{
		path: '/ProfileBlock/EventsPage174',
		name: 'EventsPage174',
		component: EventsPage174,
	},
	{
		path: '/ProfileBlock/EventsPage175',
		name: 'EventsPage175',
		component: EventsPage175,
	},
	{
		path: '/ProfileBlock/EventsPage176',
		name: 'EventsPage176',
		component: EventsPage176,
	},
	{
		path: '/ProfileBlock/EventsPage177',
		name: 'EventsPage177',
		component: EventsPage177,
	},
	{
		path: '/ProfileBlock/EventsPage178',
		name: 'EventsPage178',
		component: EventsPage178,
	},
	{
		path: '/ProfileBlock/EventsPage179',
		name: 'EventsPage179',
		component: EventsPage179,
	},
	{
		path: '/ProfileBlock/EventsPage180',
		name: 'EventsPage180',
		component: EventsPage180,
	},
	{
		path: '/ProfileBlock/EventsPage181',
		name: 'EventsPage181',
		component: EventsPage181,
	},
	{
		path: '/ProfileBlock/EventsPage182',
		name: 'EventsPage182',
		component: EventsPage182,
	},
	{
		path: '/ProfileBlock/EventsPage183',
		name: 'EventsPage183',
		component: EventsPage183,
	},
	{
		path: '/ProfileBlock/EventsPage184',
		name: 'EventsPage184',
		component: EventsPage184,
	},
	{
		path: '/ProfileBlock/EventsPage185',
		name: 'EventsPage185',
		component: EventsPage185,
	},
	{
		path: '/ProfileBlock/EventsPage186',
		name: 'EventsPage186',
		component: EventsPage186,
	},
	{
		path: '/ProfileBlock/EventsPage187',
		name: 'EventsPage187',
		component: EventsPage187,
	},
	{
		path: '/ProfileBlock/EventsPage188',
		name: 'EventsPage188',
		component: EventsPage188,
	},
	{
		path: '/ProfileBlock/EventsPage189',
		name: 'EventsPage189',
		component: EventsPage189,
	},
	{
		path: '/ProfileBlock/EventsPage190',
		name: 'EventsPage190',
		component: EventsPage190,
	},
	{
		path: '/ProfileBlock/EventsPage191',
		name: 'EventsPage191',
		component: EventsPage191,
	},
	{
		path: '/ProfileBlock/EventsPage192',
		name: 'EventsPage192',
		component: EventsPage192,
	},
	{
		path: '/ProfileBlock/EventsPage193',
		name: 'EventsPage193',
		component: EventsPage193,
	},
	{
		path: '/ProfileBlock/EventsPage194',
		name: 'EventsPage194',
		component: EventsPage194,
	},
	{
		path: '/ProfileBlock/EventsPage195',
		name: 'EventsPage195',
		component: EventsPage195,
	},
	{
		path: '/ProfileBlock/EventsPage196',
		name: 'EventsPage196',
		component: EventsPage196,
	},
	{
		path: '/ProfileBlock/EventsPage197',
		name: 'EventsPage197',
		component: EventsPage197,
	},
	{
		path: '/ProfileBlock/EventsPage198',
		name: 'EventsPage198',
		component: EventsPage198,
	},
	{
		path: '/ProfileBlock/EventsPage199',
		name: 'EventsPage199',
		component: EventsPage199,
	},
	{
		path: '/ProfileBlock/EventsPage200',
		name: 'EventsPage200',
		component: EventsPage200,
	},
	{
		path: '/ProfileBlock/EventsPage201',
		name: 'EventsPage201',
		component: EventsPage201,
	},
	{
		path: '/ProfileBlock/EventsPage202',
		name: 'EventsPage202',
		component: EventsPage202,
	},
	{
		path: '/ProfileBlock/EventsPage203',
		name: 'EventsPage203',
		component: EventsPage203,
	},
	{
		path: '/ProfileBlock/EventsPage204',
		name: 'EventsPage204',
		component: EventsPage204,
	},
	{
		path: '/ProfileBlock/EventsPage205',
		name: 'EventsPage205',
		component: EventsPage205,
	},
	{
		path: '/ProfileBlock/EventsPage206',
		name: 'EventsPage206',
		component: EventsPage206,
	},
	{
		path: '/ProfileBlock/EventsPage207',
		name: 'EventsPage207',
		component: EventsPage207,
	},
	{
		path: '/ProfileBlock/EventsPage208',
		name: 'EventsPage208',
		component: EventsPage208,
	},
	{
		path: '/ProfileBlock/EventsPage209',
		name: 'EventsPage209',
		component: EventsPage209,
	},
	{
		path: '/Registration',
		name: 'Registration',
		component: Registration,
	},
	{
		path: '/JuniorProfi',
		name: 'JuniorProfi',
		component: JuniorProfi,
	},
	{
		path: '/RemoteBlock',
		name: 'Remote',
		component: RemoteBlock,
	},
	{
		path: '/ScienceBlock',
		name: 'Science',
		component: ScienceBlock,
	},
	{
		path: '/Sing',
		name: 'Sing',
		component: Sing,
	},
	{
		path: '/vsosh2023',
		name: 'AllRussian',
		component: AllRussian,
	},
	{
		path: '/SportBlock',
		name: 'Sport',
		component: SportBlock,
	},
	{
		path: '/Trustees',
		name: 'Trustees',
		component: Trustees,
	},
	{
		path: '/HowToGet',
		name: 'HowToGet',
		component: HowToGet,
	},
	{
		path: '/BasicInformation',
		name: 'BasicInformation',
		component: BasicInformation,
	},
	{
		path: '/AdditionalGneral',
		name: 'AdditionalGneral',
		component: AdditionalGneral,
	},
	{
		path: '/AdditionalGneral2023',
		name: 'AdditionalGneral2023',
		component: AdditionalGneral2023,
	},
	{
		path: '/AdditionalGneral2024',
		name: 'AdditionalGneral2024',
		component: AdditionalGneral2024,
	},
	{
		path: '/AdditionalProfessionalPrograms',
		name: 'AdditionalProfessionalPrograms',
		component: AdditionalProfessionalPrograms,
	},
	{
		path: '/AdditionalProfessionalPrograms2023',
		name: 'AdditionalProfessionalPrograms2023',
		component: AdditionalProfessionalPrograms2023,
	},
	{
		path: '/AdditionalProfessionalPrograms2024',
		name: 'AdditionalProfessionalPrograms2024',
		component: AdditionalProfessionalPrograms2024,
	},
	{
		path: '/WeeklyCoursesScience',
		name: 'WeeklyCoursesScience',
		component: WeeklyCoursesScience,
	},
	{
		path: '/WeeklyCoursesArt',
		name: 'WeeklyCoursesArt',
		component: WeeklyCoursesArt,
	},
	{
		path: '/Training',
		name: 'Training',
		component: Training,
	},
	{
		path: '/ProfessionalEducation',
		name: 'ProfessionalEducation',
		component: ProfessionalEducation,
	},
	{
		path: '/ConferenceSeminars',
		name: 'ConferenceSeminars',
		component: ConferenceSeminars,
	},
	{
		path: '/SelectionCriteria',
		name: 'SelectionCriteria',
		component: SelectionCriteria,
	},
	{
		path: '/PaidServices',
		name: 'PaidServices',
		component: PaidServices,
	},
	{
		path: '/VacantPlaces',
		name: 'VacantPlaces',
		component: VacantPlaces,
	},
	{
		path: '/InternatiCooperation',
		name: 'InternatiCooperation',
		component: InternatiCooperation,
	},
	{
		path: '/Sirius_Summer',
		name: 'Sirius_Summer',
		component: Sirius_Summer,
	},
	{
		path: '/Sirius_Artificial_Intelligence',
		name: 'Sirius_Artificial_Intelligence',
		component: Sirius_Artificial_Intelligence,
	},
	{
		path: '/Sirius_Lessons',
		name: 'Sirius_Lessons',
		component: Sirius_Lessons,
	},
	{
		path: '/SummerHolidayRecommendations',
		name: 'SummerHolidayRecommendations',
		component: SummerHolidayRecommendations,
	},
	{
		path: '/RecommendationsForTeachers',
		name: 'RecommendationsForTeachers',
		component: RecommendationsForTeachers,
	},
	{
		path: '/PsychologicalSupport',
		name: 'PsychologicalSupport',
		component: PsychologicalSupport,
	},
	{
		path: '/VsoshPage',
		name: 'VsoshPage',
		component: VsoshPage,
	},
	{
		path: '/GoldenFundPage',
		name: 'GoldenFundPage',
		component: GoldenFundPage,
	},
	{
		path: '/OlympicPage',
		name: 'OlympicPage',
		component: OlympicPage,
	},
	{
		path: '/HighestQualityPage',
		name: 'HighestQualityPage',
		component: HighestQualityPage,
	},
	{
		path: '/TalentAndSuccessFoundationPage',
		name: 'TalentAndSuccessFoundationPage',
		component: TalentAndSuccessFoundationPage,
	},
	{
		path: '/BigChallengesPage',
		name: 'BigChallengesPage',
		component: BigChallengesPage,
	},
	{
		path: '/JuniorProfiChampionshipPage',
		name: 'JuniorProfiChampionshipPage',
		component: JuniorProfiChampionshipPage,
	},
	{
		path: '/SmartiesAndSmartiesPage',
		name: 'SmartiesAndSmartiesPage',
		component: SmartiesAndSmartiesPage,
	},
	{
		path: '/BestStudentPage',
		name: 'BestStudentPage',
		component: BestStudentPage,
	},
	{
		path: '/BestCounselorPage',
		name: 'BestCounselorPage',
		component: BestCounselorPage,
	},
	{
		path: '/BestPracticsPage',
		name: 'BestPracticsPage',
		component: BestPracticsPage,
	},
	{
		path: '/CompetitionsForTeachers',
		name: 'CompetitionsForTeachers',
		component: CompetitionsForTeachers,
	},
	{
		path: '/MunicipalCoordinators',
		name: 'MunicipalCoordinators',
		component: MunicipalCoordinators,
	},
	{
		path: '/TeachingStaff',
		name: 'TeachingStaff',
		component: TeachingStaff,
	},
	{
		path: '/Nutrition',
		name: 'Nutrition',
		component: Nutrition,
	},
	{
		path: '/EducationalStandards',
		name: 'EducationalStandards',
		component: EducationalStandards,
	},
	{
		path: '/RegulatoryDocuments',
		name: 'RegulatoryDocuments',
		component: RegulatoryDocuments,
	},
	{
		path: '/VsoshDocuments',
		name: 'VsoshDocuments',
		component: VsoshDocuments,
	},
	{
		path: '/OtherEvents',
		name: 'OtherEvents',
		component: OtherEvents,
	},
	{
		path: '/test',
		name: 'test',
		component: test,
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
