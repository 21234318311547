<template>
	<div class="OtherEvents _cover">
		<h2 class="OtherEvents-title _cover-title">
			Иные мероприятия
		</h2>

		<h3 class="OtherEvents-title _cover-title">
			Конкурсы и конференции для школьников в 2024-2025 г.г.
		</h3>
		<div class="StructureAndOrgans-table">
			<table>
				<tr>
					<td><b>Направление</b></td>
					<td><b>Название</b></td>
					<td><b>Уровень</b></td>
					<td><b>Регистрация</b></td>
					<td><b>Даты проведения</b></td>
					<td><b>Возраст участников</b></td>
					<td><b>Ссылка</b></td>
				</tr>
				<tr>
					<td>Программирование ИИ и большие данные</td>
					<td>Конкурс молодежных технологических проектов Технопарка «Сколково»</td>
					<td>Федеральный<br>(в течение всего сентября есть доступ к посещению открытых уроков)</td>
					<td>с 15 августа по 31 октября 2024 года</td>
					<td>с 1 по 11 ноября 2024 года</td>
					<td>7 – 17 лет</td>
					<td><a
							href="https://kids.sk.ru/skkidschallenge?utm_source=yandex_webpractik&utm_medium=cpc&utm_campaign=113934320|Poisk_||_Ruchnaya_||_Konkurs_2024_||_RF&utm_content=groupid:5486570940|coy:16453667142|b:premium|p:1|st:search|s:none|r:63|rn:%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA|d:desktop|adt:none&utm_term=%D0%B8%D1%81%D1%81%D0%BB%D0%B5%D0%B4%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%B8%D0%B5%20%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%8B%20%D0%B4%D0%BB%D1%8F%20%D1%88%D0%BA%D0%BE%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2&yclid=8491890679026548735"
							target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td rowspan="3">Технологии экологичного и эффективного природоиспользования</td>
					<td>V Международная детско-юношеская премия «Экология – дело каждого»</td>
					<td>Международный</td>
					<td>с 1 августа 2024 года по 1 апреля 2025 года</td>
					<td>с 1 августа 2024 года по 1 января 2026 года</td>
					<td>первая возрастная группа – дети от 6 до 9 лет (включительно);<br><br>
						вторая возрастная группа – дети от 10 до 13 лет (включительно);<br><br>
						третья возрастная группа – подростки от 14 до 18 лет (включительно)</td>
					<td><a href="https://xn--80afbcbeimqege7abfeb7wqb.xn--p1ai/doc/polozenieV.pdf" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Открытый молодежный водный конкурс</td>
					<td>Конкурс проводится в три этапа:<br>- муниципальный;<br><br>
						- региональный (на уровне субъекта Российской Федерации)/национальный (на уровне стран-участниц);<br><br>
						- международный</td>
					<td>с 1 октября 2024 года до 15 февраля 2025 года</td>
					<td>Проходит в очно-дистанционном формате в три этапа:<br>
						1) конкурс научных аннотаций до 1 марта 2025 года;<br><br>
						2) дистанционная защита проектов 25-30 марта 2025 года.<br><br>
						3) очный суперфинал в Москве в период – 15-25 апреля 2025 года.
					</td>
					<td>от 14 до 18 лет </td>
					<td><a
							href="https://vk.com/doc643803861_679605278?hash=d03BKg9joA8bkOK8Iw24r4OraPMbQ8Fq0ulHKEyGWz4&t2fs=4255a2686033d081f4_2"
							target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Конкурс достижений талантливой молодёжи «Национальное достояние России»</td>
					<td>Всероссийский</td>
					<td></td>
					<td>с 01 января по 10 февраля 2025 года </td>
					<td>с 14 лет</td>
					<td><a href="https://integraciya.org/konkursy/ndr/Polozhenie_NDR_2024-2025.pdf" target="_blank">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>Агропромышленные и биотехнологии</td>
					<td>Всероссийский детский конкурс научно-исследовательских и творческих работ «ПЕРВЫЕ ШАГИ В НАУКЕ»</td>
					<td>Всероссийский</td>
					<td></td>
					<td>01.09.2024 – 04.11.2024 - заочный тур<br><br>
						10.12.2024 – 12.12.2024 - очный тур (XXXIV Всероссийская детская конференция)</td>
					<td>7 – 14 лет</td>
					<td><a href="https://integraciya.org/konkursy/pervye-shagi-v-nauke/" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Передовая инженерия (моделирование и прототипирование новых устройств, новых технологических процессов)
					</td>
					<td>Конкурс исследовательских и проектных работ школьников «Высший пилотаж»</td>
					<td>Всероссийский</td>
					<td> с 1 октября 2024 года до 24 января 2025</td>
					<td>14-16 февраля 2025 года. Заключительный этап по направлению «Спутникостроение и геоинформационные
						технологии: Terra Notum»<br><br>
						10 марта 2025 года Публикация результатов отборочного этапа конкурса по всем направлениям<br><br>
						29-30 марта 2025 года Проведение заключительного этапа конференции «Авангард»<br><br>
						2-5 апреля 2025 года Заключительный этап по остальным направлениям конкурса</td>
					<td>учащиеся с 8 по 11 класс</td>
					<td><a href="https://olymp.hse.ru/projects/?ysclid=m0uqzp3cn3113931618" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Современная энергетика</td>
					<td>II Всероссийской научно-практической конференции «Интеллектуальная энергетика»</td>
					<td>Всероссийский</td>
					<td>до 15 октября 2024 года</td>
					<td>12 – 14 ноября 2024 года </td>
					<td>нет ограничения по возрасту, но материал, который ученик готовит на конференцию, должен быть проработан и
						перспективный с научно-практической точки зрения.</td>
					<td><a href="https://smart-energy.tpu.ru/" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td rowspan="2">Передовые химические технологии</td>
					<td>Всероссийский детский конкурс научно-исследовательских и творческих работ «ПЕРВЫЕ ШАГИ В НАУКЕ»</td>
					<td>Всероссийский</td>
					<td></td>
					<td>01.09.2024 – 04.11.2024 - заочный тур<br><br>
						10.12.2024 – 12.12.2024 - очный тур (XXXIV Всероссийская детская конференция)</td>
					<td>7 – 14 лет</td>
					<td><a href="https://integraciya.org/konkursy/pervye-shagi-v-nauke/" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>XIII Международная конференция «Наука без границ» (с применением дистанционных технологий)</td>
					<td>Международный</td>
					<td>до 25 ноября 2024 года включительно<br><br>
						очном формате (по видеоконференцсвязи, платформа BBB)</td>
					<td>02 – 07 декабря 2024 года</td>
					<td></td>
					<td><a href="https://www.sibupk.su/upload/medialibrary/636/jfmqlsxr8048y2e8o0ibttm9h42ysdhc/12.pdf"
							target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Космические технологии</td>
					<td>Конкурс достижений талантливой молодёжи «Национальное достояние России» </td>
					<td>Всероссийский </td>
					<td></td>
					<td>с 01 января по 10 февраля 2025 года</td>
					<td>с 14 лет </td>
					<td><a href="https://integraciya.org/konkursy/ndr/Polozhenie_NDR_2024-2025.pdf" target="_blank">Ссылка</a>
					</td>
				</tr>
				<tr>
					<td>Технологии обеспечения безопасности</td>
					<td>Всероссийский молодежный конкурс по проблемам культурного наследия, экологии и безопасности
						жизнедеятельности «ЮНЭКО»</td>
					<td>Всероссийский </td>
					<td></td>
					<td>01.09.2024 – 10.10.2024 - заочный тур<br><br>
						12.11.2024 – 14.11.2024 - очный тур (XXII Всероссийский молодежный форум)</td>
					<td></td>
					<td><a href="https://integraciya.org/konkursy/yuneko/" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td rowspan="2">Генетика и медицинские технологии</td>
					<td>Всероссийский детский конкурс научно-исследовательских творческих работ «ПЕРВЫЕ ШАГИ В НАУКЕ»</td>
					<td>Всероссийский </td>
					<td></td>
					<td>01.09.2024 – 04.11.2024 - заочный тур<br><br>
						10.12.2024 – 12.12.2024 - очный тур (XXXIV Всероссийская детская конференция)</td>
					<td>7 – 14 лет</td>
					<td><a href="https://integraciya.org/konkursy/pervye-shagi-v-nauke/" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Научная конференция школьников и студентов «Наука и общество: взгляд молодых исследователей»</td>
					<td>Всероссийский </td>
					<td>до 19 октября 2024 года</td>
					<td>21 – 22 ноября 2024 года</td>
					<td>учащиеся старших классов школ, гимназий и лицеев, студенты колледжей и вузов</td>
					<td><a href="https://www.sibupk.su/upload/medialibrary/912/ljtukmfosl82p8rsjk4pwz94dj920y8y/23.pdf"
							target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td rowspan="3">Когнитивные исследования</td>
					<td>Всероссийский детский конкурс научно-исследовательских и творческих работ «ПЕРВЫЕ ШАГИ В НАУКЕ»</td>
					<td>Всероссийский</td>
					<td></td>
					<td>01.09.2024 – 04.11.2024 - заочный тур<br><br>
						10.12.2024 – 12.12.2024 - очный тур (XXXIV Всероссийская детская конференция)</td>
					<td>7 -14 лет </td>
					<td><a href="https://integraciya.org/konkursy/pervye-shagi-v-nauke/" target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Научная конференция школьников и студентов «Наука и общество: взгляд молодых исследователей»</td>
					<td>Всероссийский </td>
					<td>до 19 октября 2024 года</td>
					<td>21 – 22 ноября 2024 года</td>
					<td> учащиеся старших классов школ, гимназий и лицеев, студенты колледжей и вузов</td>
					<td><a href="https://www.sibupk.su/upload/medialibrary/912/ljtukmfosl82p8rsjk4pwz94dj920y8y/23.pdf"
							target="_blank">Ссылка</a></td>
				</tr>
				<tr>
					<td>Конкурс достижений талантливой молодёжи «Национальное достояние России»</td>
					<td>Всероссийский</td>
					<td></td>
					<td>с 01 января по 10 февраля 2025 года</td>
					<td>с 14 лет </td>
					<td><a href="https://integraciya.org/konkursy/ndr/Polozhenie_NDR_2024-2025.pdf" target="_blank">Ссылка</a>
					</td>
				</tr>
			</table>
		</div>

	</div>
</template>