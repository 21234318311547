<template>
	<div>
		
		<!-- Навигатор
		<MainNavigator /> -->
		
		<!-- Банеры -->
		<!-- <MainBaner /> -->
		
		<!-- Новости -->
		<MainNews />
		
		<!-- Мероприятие -->
		<MainEvents />

		<!-- ЦУРА -->
		<MainCura />

	</div>
</template>

<script>
// import MainBaner from '@/components/MainBaner.vue';
import MainNews from '@/components/MainNews.vue';
import MainEvents from '@/components/MainEvents.vue';
import MainCura from '@/components/MainCura.vue';
// import MainNavigator from '@/components/MainNavigator.vue';

export default {
	components: {
		// MainBaner,
		MainNews,
		MainEvents,
		MainCura,
		// MainNavigator,
	}
}

</script>