<template>
	<div class="RegulatoryDocuments _cover">
		<h2 class="RegulatoryDocuments-title _cover-title">
			Нормативно правовые документы
		</h2>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D1%80%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BB%D1%83%D1%87%D1%88%D0%B8%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B-24.pdf"
					target="_blank">
					Распоряжение лучшие программы
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Уважаемые коллеги!
		</p>
		<p class="EventsPage-text">
			Подведены итоги регионального этапа всероссийского конкурса "Лучшая программа организации отдыха детей и их
			оздоровления"
		</p>
		<p class="EventsPage-text">
			Мы благодарим всех за участие!
		</p>
		<p class="EventsPage-text">
			Экспертное жюри отметило высокий уровень заявленных материалов , интересные подходы к организации отдыха детей и
			их оздоровления. Благодарим вас за профессиональную и ответственную работу!
		</p>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="https://disk.yandex.com/d/J3H5fmjOY3mRmw" target="_blank">
					Ссылка на дипломы
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			По итогам конкурсного отбора, на всероссийский этап направляются программы:
		</p>
		<p class="JuniorProfi-sub-title">
			в номинации "Программа лагерей с дневным пребыванием":
		</p>
		<p class="EventsPage-text">
			Программа летнего отдыха и оздоровления "Профкластеры" лагеря с дневным пребыванием детей "Радуга" МБУДО г.
			Иркутска Дом детского творчества № 2, авторы программы: Сальникова Елена Юрьевна, директор; Смольянинова Мария
			Анатольевна, заместитель директора по УВР; Ролич Катерина Николаевна, педагог-организатор; Переславцева Людмила
			Сергеевна, педагог-организатор; Пугачев Игорь Юрьевич, педагог-организатор; Пугачев Игорь Юрьевич,
			педагог-организатор; Бразаускайте Илона Владиславовна, педагог-организатор; Чуруксаева Тамара Яковлевна, методист;
		</p>
		<p class="EventsPage-text">
			программа "Время первых", лагерь с дневным пребыванием "Алые паруса", МКОУ ЦО "Каразей", Куйтунский район, с.
			Каразей, составитель программы: Кузнецова Людмила Геннадьевна, педагог-дефектолог;
		</p>

		<p class="JuniorProfi-sub-title">
			В номинации "Программа стационарных лагерей":
		</p>
		<p class="EventsPage-text">
			программа летнего отдыха "Мосты дружбы" УДПО ИО Учебный центр "Эдельвейс", автор составитель: Черная Лариса
			Ивановна, методист
		</p>

		<!-- <p class="JuniorProfi-sub-title">
			Сроки проведения конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				I этап - муниципальный: срок проведения до 17 сентябр я 2024 rода.
			</li>
			<li class="JuniorProfi-items">
				II этап - региональный: срок проведения с l 8 по 27 сентября 2024 rода.
			</li>
			<li class="JuniorProfi-items">
				III этап - подведение итогов и награждение победителей: срок
			</li>
		</ul>
		<p class="EventsPage-text">
			Проведения до 30 сентября2024 года
		</p>

		<p class="JuniorProfi-sub-title">
			Номинации конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Программа стационарных лагерей
			</li>
			<li class="JuniorProfi-items">
				Программа палаточных лагерей
			</li>
			<li class="JuniorProfi-items">
				Программа лагерей с дневным пребыванием
			</li>
			<li class="JuniorProfi-items">
				Программа лагерей труда и отдыха
			</li>
			<li class="JuniorProfi-items">
				Программа детских специализированных (профильных) лагерей
			</li>
			<li class="JuniorProfi-items">
				Программа детских лагерей различной тематической направленности
			</li>
			<li class="JuniorProfi-items">
				Инклюзивная программа организации отдыха и оздоровления детей
			</li>
			<li class="JuniorProfi-items">
				Информационно-методические сборники по организации отдыха детей и их оздоровления
			</li>
		</ul> -->

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Самодурова Вера Геннадьевна, руководитель Центра развития конкурсного движения и детских
				инициатив
			</li>
			<li class="JuniorProfi-items">
				Телефон: 89247117835
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: v.samodurova@perseusirk.ru
			</li>
		</ul>
	</div>
</template>